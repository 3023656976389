import React, { useState, useCallback } from "react";
import {
    List,
    ListItem,
    Button,
    Stack,
    Typography,
    CircularProgress,
    InputAdornment,
    SvgIcon,
    OutlinedInput,
    Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import usePagination from "../../../hooks/use-pagination";
import OnIcon from "../icon";
import { useTheme } from "@mui/system";
import PropTypes from "prop-types";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import debounce from "lodash.debounce";
import NoResults from "../no-results";

function groupElementsByTime(elements, groupByTimeField) {
    const groups = {
        "today": [],
        "yesterday": [],
        "last_7_days": [],
        "last_30_days": [],
        "older": []
    };

    // Sort elements by date descending
    const sortedElements = [...elements].sort((a, b) => new Date(b[groupByTimeField]) - new Date(a[groupByTimeField]));

    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterdayStart = new Date(todayStart);
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);
    const last7DaysStart = new Date(todayStart);
    last7DaysStart.setDate(last7DaysStart.getDate() - 7);
    const last30DaysStart = new Date(todayStart);
    last30DaysStart.setDate(last30DaysStart.getDate() - 30);

    sortedElements.forEach(item => {
        const dateValue = new Date(item[groupByTimeField]);
        if (dateValue >= todayStart) {
            groups["today"].push(item);
        } else if (dateValue >= yesterdayStart) {
            groups["yesterday"].push(item);
        } else if (dateValue >= last7DaysStart) {
            groups["last_7_days"].push(item);
        } else if (dateValue >= last30DaysStart) {
            groups["last_30_days"].push(item);
        } else {
            groups["older"].push(item);
        }
    });

    return groups;
}

function OverviewList(props) {

    const {
        endpoint,
        renderItem,
        query,
        height,
        pageSize = 10,
        minHeight = null,
        maxHeight = 450,
        groupByTime = null,
        noResultsIcon = 'SearchRefraction',
        noResultsText = null,
        autoFocusSearch = true,
        topItem = null,
        withSearch = false,
        ...rest
    } = props;

    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const theme = useTheme();
    const { t } = useTranslation();

    const handleQueryChange = useCallback((event) => {
        event.preventDefault();
        setSearchQuery(event.target.value);
    }, []);

    const debounceSearchUpdate = useCallback(debounce(handleQueryChange, 1000), []);

    const paginationOptions = {
        endpoint: endpoint,
        page: page,
        append: true,
        query: { ...query, ...(searchQuery ? { search: searchQuery } : {}) },
        pageSize: pageSize
    };

    const { elements, setElements, initialLoading, loading, isMore } = usePagination(paginationOptions);

    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    let groupedElements = null;
    if (groupByTime) {
        groupedElements = groupElementsByTime(elements, groupByTime);
    }

    if (initialLoading)
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    ...(height
                        ? { height: height === 'auto' ? 400 : height }
                        : maxHeight
                            ? { height: maxHeight < 400 ? maxHeight : 400 }
                            : { height: minHeight > 400 ? minHeight : 400 }),
                    ...(minHeight ? { minHeight } : {}),
                }}
                {...rest}
            >
                <CircularProgress size={20} />
            </Stack>
        )

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                ...(height ? { height } : { maxHeight }),
                ...(minHeight ? { minHeight } : {}),
            }}
        >
            {topItem && topItem}
            {withSearch && (
                <Box p={2}>
                    <OutlinedInput
                        defaultValue=""
                        fullWidth
                        sx={{ height: 45 }}
                        autoFocus={autoFocusSearch}
                        size="small"
                        inputProps={{ onChange: debounceSearchUpdate }}
                        name="itemName"
                        placeholder={t('common.search')}
                        endAdornment={loading && (
                            <InputAdornment position="end">
                                <CircularProgress size={20} />
                            </InputAdornment>
                        )}
                        startAdornment={(
                            <InputAdornment position="start">
                                <SvgIcon fontSize="small">
                                    <SearchMdIcon />
                                </SvgIcon>
                            </InputAdornment>
                        )}
                    />
                </Box>
            )}
            {elements.length === 0 ? (
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    id="no-results-overlay"
                >
                    <NoResults
                        primary={noResultsText || t("common.sst_no_results")}
                        icon={noResultsIcon}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: '100%', height: ((!height && !minHeight) || (height === 'auto')) ? 300 : '100%', textAlign: 'center' }}
                    />
                </Box>
            ) : (
                <List
                    sx={{ flexGrow: 1, overflow: 'auto' }}
                    {...rest}
                >
                    {groupByTime ? (
                        Object.entries(groupedElements).map(([groupName, items]) => (
                            items.length > 0 && (
                                <React.Fragment key={groupName}>
                                    <ListItem>
                                        <Typography variant="overline" mt={4} sx={{fontWeight: 700}}>
                                            {t(`common.${groupName}`)}
                                        </Typography>
                                    </ListItem>
                                    {items.map((item, index) => renderItem(item, index, setElements))}
                                </React.Fragment>
                            )
                        ))
                    ) : (
                        <>
                            {elements.map((item, index) => renderItem(item, index, setElements))}
                            {isMore && (
                                <ListItem>
                                    <Button
                                        fullWidth
                                        onClick={handleLoadMore}
                                        disabled={!isMore}
                                    >
                                        {t("common.load_more")}
                                    </Button>
                                </ListItem>
                            )}
                        </>
                    )}
                </List>
            )}
        </Box>
    );
}

OverviewList.propTypes = {
    endpoint: PropTypes.string.isRequired,
    renderItem: PropTypes.func.isRequired,
    query: PropTypes.object,
    groupByTime: PropTypes.string,
    pageSize: PropTypes.number,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,
    topItem: PropTypes.element,
    withSearch: PropTypes.bool,
    autoFocusSearch: PropTypes.bool,
    noResultsIcon: PropTypes.string,
    noResultsText: PropTypes.string,
}

export default OverviewList;
