import React from "react";
import {useTranslation} from "react-i18next";
import {List} from "@mui/material";
import {SideNavItem} from "../../../layouts/aios/flying-layout/side-nav-item";
import {useDialog} from "../../../../hooks/use-dialog";
import {useLocation} from "react-router-dom";
import usePagination from "../../../../hooks/use-pagination";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import SharingStatus from "../../../elements/sharing/sharing-status";
import AddCampaignDialog from "./add-campaign-dialog";

export default function CampaignsSidebarSection(){
    // TODO: Later we can have icons after type

    const { t } = useTranslation();
    const location = useLocation();
    const newCampaignDialog = useDialog();
    const { hasPermissions } = useSecurityCheck();
    const elementLoader = usePagination({
        endpoint: 'sm/campaigns',
        query: {ordering: 'created_at'},
        page: 1,
        pageSize: 500
    })

    const handleNewContext = (context) => {
        elementLoader.setElements(prev => [...prev, context]);
        newCampaignDialog.handleClose();
    }

    return (
        <>
            <List sx={{py: 0}}>
                {elementLoader.loading ? (
                    <SideNavItem
                        title={t('common.loading')}
                        icon="Announcement01"
                        disabled={true}
                    />
                ) : elementLoader.elements?.map(campaign => (
                    <SideNavItem
                        title={campaign.name}
                        icon="Announcement01"
                        active={location.pathname?.includes(`/groon/marketing/campaigns/${campaign.id}`)}
                        path={`/groon/marketing/campaigns/${campaign.id}`}
                        chip={(
                            <SharingStatus entity={campaign} minimal={true} />
                        )}
                    />
                ))}
                {hasPermissions("performance_marketing") && (
                    <SideNavItem
                        title={t('marketing.campaigns.new_campaign')}
                        icon="PlusCircle"
                        onClick={newCampaignDialog.handleOpen}
                        tooltip={t('marketing.campaigns.new_campaign_info')}
                    />
                )}
            </List>
            <AddCampaignDialog
                open={newCampaignDialog.open}
                onClose={newCampaignDialog.handleClose}
                onDone={handleNewContext}
            />
        </>
    )

}