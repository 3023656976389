import React, { useContext, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router";
import PropTypes from 'prop-types';
import {AppModeContext} from "src/omnia/context/app-mode-context";
import {useAppMode} from "../../../hooks/use-app-mode";
import LoadingScreen from "../loading-screen";
import {useTheme} from "@mui/system";
import {setUser} from "../../../store/actions/service-actions";
import {useNotifications} from "../../../hooks/use-notifications";
import {useTranslation} from "react-i18next";
import {setIsTransitioning} from "../../../store/actions/account-actions";


function ServiceGuard({ serviceIdentifier, children }) {
    const theme = useTheme();
    const user = useSelector(state => state.service.user);
    const loading = useSelector(state => state.service.loadingUser);
    const serviceId = localStorage.getItem('serviceId');
    const appMode = useAppMode();
    const dispatch = useDispatch();

    useEffect(() => {
        appMode.setIsService(true);
        appMode.setIsBackend(false);
    }, []);

    useEffect(() => {
        if(user){
            appMode.setService(user?.context || null);
            appMode.setServiceTheme({...(user?.context?.theme || theme), ...{paletteMode: user?.context?.palette_mode}});
            return () => {
                appMode.setService(null);
            }
        }
    }, [user]);

    if(!user){
        if(loading){
            return <LoadingScreen inScreen={false} />
        } else {
            localStorage.removeItem('serviceToken');
            dispatch(setUser(null));
            if(serviceId){
                return <Navigate to={'/on/login/' + serviceId} />;
            } else {
                return <Navigate to={'/'} />;
            }
        }
    } else {
        const condition = Array.isArray(serviceIdentifier) ? serviceIdentifier.includes(user?.context?.service_identifier) : user?.context?.service_identifier === serviceIdentifier;
        if(condition){
            // TODO: this loading screen is dangerous! Check it!
            if(appMode.isService){
                return children;
            } else {
                return <LoadingScreen inScreen={false} />
            }
        } else {
            console.log('ERROR: This route is not accessible for this service!')
            localStorage.removeItem('serviceToken');
            dispatch(setUser(null));
            return <Navigate to={'/on/login/' + user?.context?.id} />;
        }
    }

}

ServiceGuard.propTypes = {
    children: PropTypes.any,
    serviceIdentifier: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]).isRequired
};

export default ServiceGuard;
