import React from "react";
import PropTypes from 'prop-types';
import {useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import {MobileNav} from '../mobile-nav';
import {TopNav} from './top-nav';
import {useMobileNav} from './use-mobile-nav';
import ErrorHandler from "../../../elements/error-handler";

const HorizontalLayoutRoot = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%'
});

const HorizontalLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

export const HorizontalLayout = (props) => {

    const {children, navColor, menuItems, actionButton, tenantSwitch, sections} = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const mobileNav = useMobileNav();

    return (
        <>
            <TopNav
                color={navColor}
                onMobileNav={mobileNav.handleOpen}
                sections={sections}
                menuItems={menuItems}
                tenantSwitch={tenantSwitch}
                actionButton={actionButton}
            />
            {!lgUp && (
                <MobileNav
                    tenantSwitch={tenantSwitch}
                    actionButton={actionButton}
                    color={navColor}
                    onClose={mobileNav.handleClose}
                    open={mobileNav.open}
                    sections={sections}
                />
            )}
            <HorizontalLayoutRoot>
                <HorizontalLayoutContainer>
                    <ErrorHandler>
                        {children}
                    </ErrorHandler>
                </HorizontalLayoutContainer>
            </HorizontalLayoutRoot>
        </>
    );
};

HorizontalLayout.propTypes = {
    children: PropTypes.node,
    navColor: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
    sections: PropTypes.array,
    tenantSwitch: PropTypes.node,
    actionButton: PropTypes.node,
    menuItems: PropTypes.array
};
