import React, {useCallback, useMemo, useRef, useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Stack,
    Tooltip, Typography
} from "@mui/material";
import UserStatus from "./user-status";
import UserOnlineDot from "../UserOnlineDot";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {updateUserAvatar} from "../../../../store/actions/users-actions";
import {updateOwnAvatar} from "../../../../store/actions/account-actions";
import useApi from "../../../../hooks/use-api";
import {useNotifications} from "../../../../hooks/use-notifications";
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";
import Avatar from "@mui/material/Avatar";
import {Link as RouterLink} from "react-router-dom";
import {useDialog} from "../../../../hooks/use-dialog";
import NoResults from "../../../elements/no-results";

function UserHeader(props){

    const {
        userId = null,
        sx = {},
        avatarSizePx = 35,
        noAvatar = false,
        tooltipPlacement = 'bottom',
        dotOnly = true,
        withText = false,
        ...rest
    } = props;

    const account = useSelector((state) => state.account.user);
    const users = useSelector(state => state.users.users);
    const user = useMemo(() => {
        if(users?.filter(u => u.id === userId || account?.id)?.length > 0)
            return users.filter(u => u.id === (userId || account?.id))[0];
        return null;
    }, [users, userId, account?.id])
    const isThisUser = account?.id === user?.id;
    const avatarDialog = useDialog();
    const { t } = useTranslation();
    const [avatarIsUploading, setAvatarIsUploading] = useState(false);
    const avatarUploadRef = useRef(null);
    const {put} = useApi();
    const { notifySuccess } = useNotifications();
    const dispatch = useDispatch();

    const handleAvatarUpload = () => {
        setAvatarIsUploading(true);
        put('/core/me/change_avatar', {
            file: avatarUploadRef.current.files[0]
        }).then(avatar => {
            dispatch(updateUserAvatar(user, avatar));
            dispatch(updateOwnAvatar(avatar));
            notifySuccess(t);
        }).finally(() => {
            setAvatarIsUploading(false)
        });
    }

    const handleChangeAvatar = useCallback(() => {
        if(!isThisUser)
            return;
        avatarUploadRef.current.click();
    }, [isThisUser])

    return (
        <Stack direction="row" alignItems="center" spacing={1} sx={{...{width: '100%'}, ...sx}} {...rest}>
            {!noAvatar && (
                <Tooltip placement={tooltipPlacement} title={isThisUser ? t('core.click_to_change_image') : null}>
                    <Avatar
                        src={!avatarIsUploading && (user?.avatar?.view || null)}
                        sx={{cursor: 'pointer', height: avatarSizePx, width: avatarSizePx}}
                        onClick={avatarDialog.handleOpen}
                    >
                        {avatarIsUploading ? <CircularProgress size={20}/> : <OnIcon iconName="User01"/>}
                    </Avatar>
                </Tooltip>
            )}
            <Stack sx={{width: '100%'}}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Link
                        underline="hover"
                        variant="h6"
                        color="inherit"
                        to={'/groon/home/profile/' + user?.id}
                        component={RouterLink}
                    >
                        {user?.name}
                    </Link>

                    <UserOnlineDot userId={user?.id} dotOnly={dotOnly} withText={withText} />
                </Stack>
                <UserStatus
                    user={user}
                    withUntil={isThisUser}
                    color="text.secondary"
                    variant="body2"
                />
            </Stack>
            <input type="file" style={{display: "none"}} ref={avatarUploadRef} onChange={handleAvatarUpload}/>
            <Dialog open={avatarDialog.open} onClose={avatarDialog.handleClose}>
                <DialogTitle>
                    <Typography variant="h4">
                        {user?.name}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{p: 0, pb: 1}}>
                    {user?.avatar?.view ? (
                        <Box
                            component="img"
                            src={user?.avatar?.view}
                            alt={`${user?.name} avatar`}
                            sx={{
                                maxWidth: '800px',
                                maxHeight: '800px',
                                minWidth: '400px',
                                minHeight: '400px',
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                display: 'block',
                                margin: '0 auto',
                            }}
                        />
                    ) : (
                        <NoResults
                            icon="Image02"
                            primary={t('core.no_image')}
                            sx={{ height: 400, width: 400 }}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ width: '100%' }}
                        justifyContent="space-between"
                    >
                        <Button onClick={avatarDialog.handleClose} variant="outlined">
                            {t('common.close')}
                        </Button>
                        {isThisUser && (
                            <Button variant="contained" color="primary" onClick={handleChangeAvatar}>
                                {t('core.upload_image')}
                            </Button>
                        )}
                    </Stack>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

UserHeader.propTypes = {
    userId: PropTypes.number,
    sx: PropTypes.object,
    noAvatar: PropTypes.bool,
    tooltipPlacement: PropTypes.string,
    dotOnly: PropTypes.bool,
    withText: PropTypes.bool,
    avatarSizePx: PropTypes.number,
}

export default UserHeader;