import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    Grid,
    CardMedia,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    colors,
    Link,
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { bytesToSize } from "src/omnia/utils/bytesToSize";
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import FileRenameDialog from "./file-rename-dialog";
import MoreIcon from "@untitled-ui/icons-react/build/esm/DotsVertical";
import FileModal from "./file-modal";
import {useTheme} from "@mui/system";
import {imageTypes} from "../../../utils/file-type-helpers";


function FileCard(props) {

    const {
        file,
        deleteCallback,
        onUpdate,
        onMoved,
        allowActions,
        selected,
        showMedia = true,
        ...rest
    } = props;

    const theme = useTheme();
    const moreRef = useRef(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const getSrc = useCreateSrc();
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [fileToRename, setFileToRename] = useState(null);

    const downloadFile = () => {
        window.location.href = file.view;
    }

    const handleMenuOpen = () => {
        setOpenMenu(true);
    };

    const handleMenuClose = () => {
        setOpenMenu(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    const renameFile = (file) => {
        setFileToRename(file);
        setRenameDialogOpen(true);
    }

    const handleClose = () => {
        setFileToRename(null);
        setRenameDialogOpen(false);
    }

    return (
        <Card
            variant="outlined"
            {...rest}
        >

            <FileModal
                open={openDialog}
                file={file}
                onClose={handleCloseDialog}
                onUpdate={onUpdate}
                onMoved={onMoved}
                deleteCallback={deleteCallback}
            />

            {fileToRename !== null ? (
                <FileRenameDialog open={renameDialogOpen} file={fileToRename} handleClose={handleClose} onSuccess={onUpdate} />
            ) : null}
            {showMedia && (
                <Link
                    underline="hover"
                    variant="body1"
                    style={{cursor: 'pointer'}}
                    color="text.primary"
                    onClick={handleOpenDialog}
                >
                    {imageTypes.includes(file.type) ? (
                        <CardMedia style={{height: 150}} image={file.view_highest}/>
                    ) : (
                        <div style={{height: 150,
                            backgroundColor: colors.blueGrey[50],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'}}>
                            <InsertDriveFileIcon style={{
                                height: theme.spacing(6),
                                width: theme.spacing(6),
                                fontSize: theme.spacing(6)
                            }} />
                        </div>
                    )}
                </Link>
            )}

            <Grid container sx={{p: 1}}>
                <Grid item xs={10}>
                    <Box>
                        <Link
                            underline="hover"
                            style={{cursor: 'pointer'}}
                            maxWidth='100%'
                            onClick={handleOpenDialog}
                            color="text.primary"
                        >
                            <Typography noWrap variant="subtitle1" color="textPrimary">
                                {file.name}
                            </Typography>
                        </Link>
                        <Typography variant="caption" color="textPrimary">
                            {bytesToSize(file.size)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <IconButton edge="end" onClick={handleMenuOpen} ref={moreRef} size="small">
                        <MoreIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Menu
                anchorEl={moreRef.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                style={{
                    width: 250,
                    maxWidth: '100%'
                }}
                onClose={handleMenuClose}
                elevation={1}
                open={openMenu}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <MenuItem onClick={() => {
                    handleMenuClose();
                    handleOpenDialog();
                }}>
                    <ListItemText primary="Ansehen" />
                </MenuItem>
                <MenuItem onClick={() => {
                    renameFile(file);
                    handleMenuClose();
                }}>
                    <ListItemText primary="Umbenennen" />
                </MenuItem>
                <MenuItem onClick={downloadFile}>
                    <ListItemText primary="Herunterladen" />
                </MenuItem>
                <MenuItem onClick={() => {
                    deleteCallback(file.id);
                    handleMenuClose();
                }}>
                    <ListItemText primary="Löschen" />
                </MenuItem>
            </Menu>
        </Card>
    );
}

FileCard.propTypes = {
    className: PropTypes.string,
    file: PropTypes.object.isRequired,
    deleteCallback: PropTypes.func,
    onUpdate: PropTypes.func,
    onMoved: PropTypes.func,
    showMedia: PropTypes.bool
};

export default FileCard;
