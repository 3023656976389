import React, {memo, useMemo} from "react";
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import {Avatar, Box, ButtonBase, SvgIcon, Tooltip} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {AccountPopover} from './account-popover';
import {useSelector} from "react-redux";
import {useCreateSrc} from "../../../../hooks/use-create-src";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useTranslation} from "react-i18next";

export const AccountButton = () => {

    const user = useSelector(state => state.account.user);
    const popover = usePopover();
    const { isFlying } = useIsMobile();
    const { t } = useTranslation();
    const createSource = useCreateSrc();

    const handleOpenProfile = () => {
        popover.handleOpen();
    }

    return (
        <>
            <Tooltip enterDelay={1000} title={t("common.tooltips.show_account_menu")} placement={isFlying ? 'left' : 'bottom'}>
                <Box
                    component={ButtonBase}
                    onClick={handleOpenProfile}
                    ref={popover.anchorRef}
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: 'divider',
                        height: 40,
                        width: 40,
                        borderRadius: '50%'
                    }}
                >
                    <Avatar
                        sx={{
                            height: 32,
                            width: 32
                        }}
                        src={createSource(user.avatar)}
                    >
                        <SvgIcon>
                            <User01Icon/>
                        </SvgIcon>
                    </Avatar>
                </Box>
            </Tooltip>
            <AccountPopover popover={popover} />
        </>
    );
};
