import React from "react";
import PropTypes from 'prop-types';
import {Stack} from '@mui/material';
import {TopNavItem} from './top-nav-item';

export const TopNavSection = (props) => {
    const {section = null, pathname, ...rest} = props;
    const items = section?.items || [];

    return (
        <Stack
            component="ul"
            direction="row"
            spacing={1}
            sx={{
                listStyle: 'none',
                m: 0,
                p: 0
            }}
            {...rest}
        >
            {items.map((item) => {
                const checkPath = !!(item.path && pathname);
                const partialMatch = checkPath ? pathname.includes(item.path) : false;
                const exactMatch = checkPath ? pathname === item.path : false;

                // Branch
                if (item.items) {
                    return (
                        <TopNavItem
                            key={'item-' + item?.title}
                            active={partialMatch}
                            item={item}
                        />
                    );
                }

                // Leaf
                return (
                    <TopNavItem
                        key={'item-' + item?.title}
                        active={exactMatch}
                        item={item}
                    />
                );
            })}
        </Stack>
    );
};

TopNavSection.propTypes = {
    section: PropTypes.object.isRequired,
    pathname: PropTypes.string,
};
