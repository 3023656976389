import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import PropTypes from "prop-types";

function ClickableInput(props) {

    const { children, onClick, disabled = false, selected = false, sx = {}, ...rest } = props;

    const theme = useTheme();

    return (
        <Box
            component="button"
            type="button"
            onClick={onClick}
            sx={{
                width: "100%",
                height: 38,
                textAlign: "left",
                padding: theme.spacing(1),
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme?.config?.input_radius + 'px',
                backgroundColor: theme.palette.background.paper,
                cursor: !disabled ? "pointer" : "not-allowed",
                transition: theme.transitions.create(["border-color", "box-shadow"], {
                    duration: theme.transitions.duration.short,
                }),
                outline: "none",

                // Remove default button styles
                borderWidth: 1,
                borderStyle: "solid",
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.fontSize,
                "&:hover": disabled ? {} : {
                    backgroundColor: theme.palette.action.hover,
                },

                ...(selected ? {
                    borderWidth: 2,
                    borderColor: theme.palette.primary.main
                } : {}),

                ...sx,
            }}
            {...rest}
        >
            {children}
        </Box>
    );
}

ClickableInput.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    sx: PropTypes.object,
};

export default ClickableInput;
