import React from "react";
import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import {Box, IconButton, Stack, SvgIcon, useMediaQuery} from '@mui/material';
import {alpha} from '@mui/material/styles';
import {AccountButton} from '../account-button';
import {NotificationsButton} from '../notifications-button';
import {TeamButton} from "../team-button";
import {useDispatch, useSelector} from "react-redux";
import {setCollapsedNavbar} from "../../../../store/actions/service-actions";
import OnIcon from "../../../elements/icon";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 250;

export const TopNav = (props) => {
    const {onMobileNavOpen, menuItems, ...other} = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const dispatch = useDispatch();
    const collapseNavbar = useSelector(state => state.service.collapseNavbar);

    const handleExpandMenu = () => {
        dispatch(setCollapsedNavbar(false));
    }

    return (
        <Box
            component="header"
            sx={{
                // backdropFilter: 'blur(6px)',
                // backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
                position: 'fixed',
                left: {
                    lg: `${collapseNavbar ? 0 : SIDE_NAV_WIDTH}px`
                },
                top: 0,
                width: {
                    lg: `calc(100% - ${collapseNavbar ? 0 : SIDE_NAV_WIDTH}px)`
                },
                zIndex: (theme) => theme.zIndex.appBar
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    minHeight: TOP_NAV_HEIGHT,
                    px: 2
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    {!lgUp ? (
                        <IconButton onClick={onMobileNavOpen}>
                            <SvgIcon>
                                <Menu01Icon/>
                            </SvgIcon>
                        </IconButton>
                    ) : collapseNavbar ? (
                        <IconButton onClick={handleExpandMenu}>
                            <OnIcon iconName="Menu03" />
                        </IconButton>
                    ) : null}
                    {/*<SearchButton/>*/}
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    <TeamButton/>
                    <NotificationsButton/>
                    <AccountButton menuItems={menuItems} />
                </Stack>
            </Stack>
        </Box>
    );
};

TopNav.propTypes = {
    onMobileNavOpen: PropTypes.func
};
