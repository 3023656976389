/* eslint-disable react/no-array-index-key */
import React, {lazy, Suspense} from 'react';
import {Navigate, Outlet} from 'react-router';
import {Layout as OmniaLayout} from 'src/omnia/components/layouts/aios';
import {Layout as AuthLayout} from 'src/omnia/components/layouts/auth/classic-layout';
import AuthGuard from 'src/omnia/components/general/auth/AuthGuard';
import LoadingScreen from "./components/general/loading-screen";
import GuestGuard from "./components/general/auth/GuestGuard";
import Agents from "./views/ai-studio/agents";

// Pages
const Login = lazy(() => import('src/omnia/views/home/pages/login'));
const Setup = lazy(() => import('src/omnia/views/home/pages/setup'));
const Reset = lazy(() => import('src/omnia/views/home/pages/reset'));
const NotFound = lazy(() => import('src/omnia/views/home/pages/404'));

// AI Studio
const AIStudioIndex = lazy(() => import('src/omnia/views/ai-studio'));
const AIStudioTraining = lazy(() => import('src/omnia/views/ai-studio/training'));
const AIStudioTrainings = lazy(() => import('src/omnia/views/ai-studio/trainings'));
const AIStudioModel = lazy(() => import('src/omnia/views/ai-studio/model'));
const AIStudioModels = lazy(() => import('src/omnia/views/ai-studio/models'));

// Analytics
const AnalyticsDashboards = lazy(() => import('src/omnia/views/analytics/dashboards'));
const AnalyticsDashboard = lazy(() => import('src/omnia/views/analytics/dashboard'));
const AnalyticsDataHub = lazy(() => import('src/omnia/views/analytics/data-hub'));
const AnalyticsDatasets = lazy(() => import('src/omnia/views/analytics/datasets'));
const AnalyticsDataset = lazy(() => import('src/omnia/views/analytics/dataset'));
const AnalyticsShortLinks = lazy(() => import('src/omnia/views/analytics/short-links'));

// Connections
const ConnectionsIndex = lazy(() => import('src/omnia/views/connections'));
const ConnectionsContexts = lazy(() => import('src/omnia/views/connections/contexts'));
const ConnectionsContext = lazy(() => import('src/omnia/views/connections/context'));
const ConnectionsContacts = lazy(() => import('src/omnia/views/connections/contacts'));
const ConnectionsPartners = lazy(() => import('src/omnia/views/connections/partners'));
const ConnectionsSupplier = lazy(() => import('src/omnia/views/connections/supplier'));
const ConnectionsCustomers = lazy(() => import('src/omnia/views/connections/customers'));
const ConnectionsContact = lazy(() => import('src/omnia/views/connections/contact'));
const ConnectionsLeads = lazy(() => import('src/omnia/views/connections/leads'));
const ConnectionsLead = lazy(() => import('src/omnia/views/connections/lead'));
const ConnectionsLeadQuery = lazy(() => import('src/omnia/views/connections/lead-query'));
const ConnectionsGraph = lazy(() => import('src/omnia/views/connections/graph'));
const ConnectionsLogin = lazy(() => import('src/omnia/views/connections/login'));  // External
const ConnectionsRegister = lazy(() => import('src/omnia/views/connections/register'));  // External
const ConnectionsOptIn = lazy(() => import('src/omnia/views/connections/opt-in'));  // External
const ConnectionsOptOut = lazy(() => import('src/omnia/views/connections/opt-out'));  // External

// Creative Studio
const CreativeStudioProjects = lazy(() => import('src/omnia/views/creative-studio/projects'));
const CreativeStudioProject = lazy(() => import('src/omnia/views/creative-studio/project'));
const CreativeStudioImageCreator = lazy(() => import('src/omnia/views/creative-studio/image-creator'));
const CreativeStudioTextCreator = lazy(() => import('src/omnia/views/creative-studio/text-creator'));
const CreativeStudioMedia = lazy(() => import('src/omnia/views/creative-studio/created-media'));

// Home
const HomeIndex = lazy(() => import('src/omnia/views/home'));
const HomeAIChat = lazy(() => import('src/omnia/views/home/ai-chat'));
const HomeAssistant = lazy(() => import('src/omnia/views/home/assistant'));
const HomeAssistants = lazy(() => import('src/omnia/views/home/assistants'));
const HomeTasks = lazy(() => import('src/omnia/views/home/my-tasks'));  // TODO: move to spaces
const HomeFiles = lazy(() => import("src/omnia/views/home/my-files"))  // TODO: move to spaces
const HomeEmails = lazy(() => import('src/omnia/views/home/emails'));
const HomeCalendar = lazy(() => import('src/omnia/views/home/calendar'));  // TODO: move to spaces
const HomeChats = lazy(() => import('src/omnia/views/home/chat'));
const HomeTickets = lazy(() => import('src/omnia/views/home/my-tickets'));
const HomeProfile = lazy(() => import('src/omnia/views/home/profile'));
const HomeTeam = lazy(() => import('src/omnia/views/home/group'));
const HomeLearningCenter = lazy(() => import('src/omnia/views/home/learning-center'));
const HomeSettings = lazy(() => import('src/omnia/views/home/settings'));
const HomeDevice = lazy(() => import("src/omnia/views/home/device"));
const HomeUser = lazy(() => import("src/omnia/views/home/user"));
const HomeArchive = lazy(() => import("src/omnia/views/home/archive"));
const HomeThemeBuilding = lazy(() => import("src/omnia/views/home/theme-builder"));
const HomeOAuth2Callback = lazy(() => import('src/omnia/views/home/oauth2'));  // External

// Control Center
const ControlCenterIndex = lazy(() => import('src/omnia/views/control-center/index'));
const ControlCenterArchitecture = lazy(() => import('src/omnia/views/control-center/architecture'));
const ControlCenterSecurity = lazy(() => import('src/omnia/views/control-center/security'));
const ControlCenterStorage = lazy(() => import('src/omnia/views/control-center/storage'));
const ControlCenterPermissions = lazy(() => import('src/omnia/views/control-center/permissions'));
const ControlCenterJobs = lazy(() => import('src/omnia/views/control-center/jobs'));
const ControlCenterJob = lazy(() => import('src/omnia/views/control-center/job'));
const ControlCenterTasks = lazy(() => import('src/omnia/views/control-center/tasks'));
const ControlCenterAIEngine = lazy(() => import('src/omnia/views/control-center/ai-engine'));
const ControlCenterConnectors = lazy(() => import('src/omnia/views/control-center/connectors'));
const ControlCenterServiceManagement = lazy(() => import('src/omnia/views/control-center/service-management'));

// Marketing
const MarketingIndex = lazy(() => import('src/omnia/views/marketing'));
const MarketingPublishing = lazy(() => import('src/omnia/views/marketing/publishing'));
const MarketingChannels = lazy(() => import('src/omnia/views/marketing/channels'));
const MarketingChannel = lazy(() => import('src/omnia/views/marketing/channel'));
const MarketingPlatforms = lazy(() => import('src/omnia/views/marketing/platforms'));
const MarketingAdAccounts = lazy(() => import('src/omnia/views/marketing/ad-accounts'));
const MarketingAdAccount = lazy(() => import('src/omnia/views/marketing/ad-account'));
const MarketingAdsPlatform = lazy(() => import('src/omnia/views/marketing/ads-platform'));
const MarketingCampaigns = lazy(() => import('src/omnia/views/marketing/campaigns'));
const MarketingCampaign = lazy(() => import('src/omnia/views/marketing/campaign'));
const MarketingAd = lazy(() => import('src/omnia/views/marketing/ad'));
const MarketingCompetitors = lazy(() => import('src/omnia/views/marketing/competitors'));
const MarketingCompetitor = lazy(() => import('src/omnia/views/marketing/competitor'));

// Resources
const ResourcesFinancialAccounting = lazy(() => import('src/omnia/views/resources/financial-accounting/index'));
const ResourcesTimeManagement = lazy(() => import('src/omnia/views/resources/time-tracking/index'));
const ResourcesFinancialAccount = lazy(() => import('src/omnia/views/resources/financial-accounting/account'));
const ResourcesTransactions = lazy(() => import('src/omnia/views/resources/transactions'));
const ResourcesTransaction = lazy(() => import('src/omnia/views/resources/transaction'));
const ResourcesProducts = lazy(() => import('src/omnia/views/resources/products'));
const ResourcesProduct = lazy(() => import('src/omnia/views/resources/product'));
const ResourcesContracts = lazy(() => import('src/omnia/views/resources/contracts'));
const ResourcesOffers = lazy(() => import('src/omnia/views/resources/offers'));
const ResourcesOffer = lazy(() => import('src/omnia/views/resources/offer'));
const ResourcesOrders = lazy(() => import('src/omnia/views/resources/orders'));
const ResourcesOrder = lazy(() => import('src/omnia/views/resources/order'));
const ResourcesSubscriptions = lazy(() => import('src/omnia/views/resources/subscriptions'));
const ResourcesSubscription = lazy(() => import('src/omnia/views/resources/subscription'));
const ResourcesWarehousesIndex = lazy(() => import('src/omnia/views/resources/warehouse-management/index'));
const ResourcesWarehouses = lazy(() => import('src/omnia/views/resources/warehouse-management/warehouses'));
const ResourcesInventory = lazy(() => import('src/omnia/views/resources/warehouse-management/inventory'));
const ResourcesInvoices = lazy(() => import('src/omnia/views/resources/invoices'));
const ResourcesInvoice = lazy(() => import('src/omnia/views/resources/invoice'));

// Spaces
const ProjectsList = lazy(() => import('src/omnia/views/spaces/list'));
const ProjectsCreate = lazy(() => import('src/omnia/views/spaces/create'));
const ProjectsProject = lazy(() => import('src/omnia/views/spaces/space'));
const ProjectsFinished = lazy(() => import('src/omnia/views/spaces/finished'));
const ProjectsBrowse = lazy(() => import('src/omnia/views/spaces/browse'));

// Website
const WebsiteIndex = lazy(() => import('src/omnia/views/website'));
const WebsiteVisitors = lazy(() => import('src/omnia/views/website/visitors'));
const WebsiteVisitor = lazy(() => import('src/omnia/views/website/visitor'));
const WebsiteSessions = lazy(() => import('src/omnia/views/website/sessions'));
const WebsiteSession = lazy(() => import('src/omnia/views/website/session'));
const WebsiteSite = lazy(() => import('src/omnia/views/website/site'));
const WebsiteSites = lazy(() => import('src/omnia/views/website/sites'));
const WebsiteArticles = lazy(() => import('src/omnia/views/website/articles'));
const WebsiteArticle = lazy(() => import('src/omnia/views/website/article'));
const WebsiteConfiguration = lazy(() => import('src/omnia/views/website/configuration'));
const WebsiteBuilder = lazy(() => import('src/omnia/views/website/cms-builder'));  // External

// Workflows
const CoreWorkflows = lazy(() => import('src/omnia/views/workflows/workflows'));
const CoreWorkflow = lazy(() => import('src/omnia/views/workflows/workflow'));
const CoreWorkflowExecution = lazy(() => import('src/omnia/views/workflows/workflow-exec'));


export const mergeRoutes = (customRoutes = []) => {

    // Initiate routes object
    let routesConfig = [];

    // Append login, setup and reset-password view
    routesConfig = routesConfig.concat([

        // AIOS Authentication
        {
            path: '/login',
            element: <Login/>,
        },
        {
            path: '/setup',
            element: <Setup/>,
        },
        {
            path: '/reset/:key',
            element: <Reset/>,
        },

        // AIOS Modules External Interfaces
        {
            path: '/on/register/:contextId',
            element: <ConnectionsRegister/>
        },
        {
            path: '/on/login/:contextId',
            element: <ConnectionsLogin/>
        },
    ])

    // Home
    let omniaRoutes = [
        {
            path: '/groon',
            element: <Navigate to="/groon/home"/>
        },
        {
            path: '/groon/home/:chatId?',
            element: <HomeIndex/>
        },
        {
            path: '/groon/home/ai-chats/:chatId',
            element: <HomeAIChat/>
        },
        {
            path: '/groon/home/assistants/:assistantId',
            element: <HomeAssistant/>
        },
        {
            path: '/groon/home/assistants',
            element: <HomeAssistants/>
        },
        {
            path: '/groon/home/files',
            element: <HomeFiles/>
        },
        {
            path: '/groon/home/archive',
            element: <HomeArchive/>
        },
        {
            path: '/groon/home/calendar',
            element: <HomeCalendar/>
        },
        {
            path: '/groon/home/emails',
            element: <HomeEmails />
        },
        {
            path: '/groon/home/tasks',
            element: <HomeTasks/>
        },
        {
            path: '/groon/home/requests',
            element: <HomeTickets/>
        },
        {
            path: '/groon/home/team/:groupId',
            element: <HomeTeam/>
        },
        {
            path: '/groon/home/profile',
            element: <HomeProfile/>
        },
        {
            path: '/groon/home/profile/:userId',
            element: <HomeProfile/>
        },
        {
            path: '/groon/home/users/:userId',
            element: <HomeUser/>
        },
        {
            path: '/groon/home/chat',
            element: <HomeChats/>
        },
        {
            path: '/groon/home/chat/:threadKey',
            element: <HomeChats/>
        },
        {
            path: '/groon/home/learning-center',
            element: <HomeLearningCenter/>
        },
        {
            path: '/groon/home/device/:deviceId',
            element: <HomeDevice/>
        },
        {
            path: '/groon/home/themes',
            element: <HomeThemeBuilding/>
        },
        {
            exact: true,
            path: '/groon/home/settings',
            element: <HomeSettings/>
        },
        {
            path: '/groon/home/callbacks/oauth2callback',
            element: <HomeOAuth2Callback/>
        }
    ];

    // Control Center
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/control-center',
            element: <ControlCenterIndex/>
        },
        {
            path: '/groon/control-center/architecture',
            element: <ControlCenterArchitecture/>
        },
        {
            path: '/groon/control-center/security',
            element: <ControlCenterSecurity/>
        },
        {
            path: '/groon/control-center/storage',
            element: <ControlCenterStorage/>
        },
        {
            path: '/groon/control-center/permissions',
            element: <ControlCenterPermissions/>
        },
        {
            path: '/groon/control-center/jobs',
            element: <ControlCenterJobs/>
        },
        {
            exact: true,
            path: '/groon/control-center/job/:jobId',
            element: <ControlCenterJob />
        },
        {
            path: '/groon/control-center/tasks',
            element: <ControlCenterTasks/>
        },
        {
            path: '/groon/control-center/ai-engine',
            element: <ControlCenterAIEngine/>
        },
        {
            path: '/groon/control-center/connectors',
            element: <ControlCenterConnectors/>
        },
        {
            path: '/groon/control-center/service-management',
            element: <ControlCenterServiceManagement/>
        }
    ])

    // AI Studio
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/ai-studio',
            element: <AIStudioIndex/>
        },
        {
            path: '/groon/ai-studio/models',
            element: <AIStudioModels/>
        },
        {
            path: '/groon/ai-studio/models/:modelId',
            element: <AIStudioModel/>
        },
        {
            path: '/groon/ai-studio/trainings',
            element: <AIStudioTrainings/>
        },
        {
            path: '/groon/ai-studio/trainings/:trainingId',
            element: <AIStudioTraining/>
        },
        {
            path: '/groon/ai-studio/agents',
            element: <Agents/>
        },
    ])

    // Analytics
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/analytics',
            element: <Navigate to='/groon/analytics/datahub'/>
        },
        {
            path: '/groon/analytics/dashboards',
            element: <AnalyticsDashboards/>
        },
        {
            path: '/groon/analytics/dashboards/:dashboardId',
            element: <AnalyticsDashboard/>
        },
        {
            path: '/groon/analytics/short-links',
            element: <AnalyticsShortLinks/>
        },
        {
            // rights placed
            path: '/groon/analytics/datahub',
            element: <AnalyticsDataHub/>
        },
        {
            path: '/groon/analytics/datasets/:datasetId',
            element: <AnalyticsDataset/>
        },
        {
            path: '/groon/analytics/datasets',
            element: <AnalyticsDatasets/>
        },
    ]);

    // Creative Studio
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/creative-studio',
            element: <Navigate to='/groon/creative-studio/image-creator'/>
        },
        {
            path: '/groon/creative-studio/created-media',
            element: <CreativeStudioMedia/>
        },
        {
            path: '/groon/creative-studio/projects',
            element: <CreativeStudioProjects/>
        },
        {
            path: '/groon/creative-studio/projects/:projectId',
            element: <CreativeStudioProject/>
        },
        {
            path: '/groon/creative-studio/image-creator',
            element: <CreativeStudioImageCreator/>
        },
        {
            path: '/groon/creative-studio/text-creator',
            element: <CreativeStudioTextCreator/>
        },
    ])

    // Connections
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/connections',
            element: <Navigate to="/groon/connections/dashboard"/>
        },
        {
            path: '/groon/connections/contexts',
            element: <ConnectionsContexts/>
        },
        {
            path: '/groon/connections/context/:contextId',
            element: <ConnectionsContext/>
        },
        // TODO: out of order
        {
            path: '/groon/connections/index',
            element: <ConnectionsContacts/>
        },

        {
            path: '/groon/connections/customers',
            element: <ConnectionsCustomers/>
        },
        {
            path: '/groon/connections/partners',
            element: <ConnectionsPartners/>
        },
        {
            path: '/groon/connections/supplier',
            element: <ConnectionsSupplier/>
        },
        {
            path: '/groon/connections/contacts',
            element: <ConnectionsContacts/>
        },

        {
            path: '/groon/connections/contact/:contactId/:tab?',
            element: <ConnectionsContact/>
        },
        {
            path: '/groon/connections/leads',
            element: <ConnectionsLeads/>
        },
        {
            path: '/groon/connections/leads/:leadId',
            element: <ConnectionsLead/>
        },
        {
            path: '/groon/connections/lead-query/:queryId',
            element: <ConnectionsLeadQuery/>
        },
        {
            path: '/groon/connections/graph',
            element: <ConnectionsGraph/>
        },
        {
            // rights placed
            path: '/groon/connections/dashboard',
            element: <ConnectionsIndex/>
        },
    ])

    // Marketing
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/marketing',
            element: <MarketingIndex/>
        },

        // Competitors
        {
            path: '/groon/marketing/competitors',
            element: <MarketingCompetitors/>
        },
        {
            // rights placed
            path: '/groon/marketing/competitor/:competitorId?',
            element: <MarketingCompetitor/>
        },

        // Online Marketing
        {
            path: '/groon/marketing/channels',
            element: <MarketingChannels/>
        },
        {
            path: '/groon/marketing/channels/:channelId',
            element: <MarketingChannel/>
        },
        {
            path: '/groon/marketing/publishing',
            element: <MarketingPublishing/>
        },
        {
            path: '/groon/marketing/ad-accounts/:adAccountId',
            element: <MarketingAdAccount/>
        },
        {
            path: '/groon/marketing/ad-accounts',
            element: <MarketingAdAccounts/>
        },
        {
            path: '/groon/marketing/platforms',
            element: <MarketingPlatforms/>
        },
        {
            path: '/groon/marketing/adsplatform/:PlatformId',
            element: <MarketingAdsPlatform/>

        },
        {
            path: '/groon/marketing/campaigns',
            element: <MarketingCampaigns/>
        },
        {
            path: '/groon/marketing/campaigns/:campaignId',
            element: <MarketingCampaign/>
        },
        {
            path: '/groon/marketing/ads/:adId',
            element: <MarketingAd/>
        }
    ])

    // Resources
    omniaRoutes = omniaRoutes.concat([

        // Index
        {
            path: '/groon/resources',
            element: <Navigate to='/groon/resources/financial-accounting'/>
        },

        // Transaction Templates
        {
            path: '/groon/resources/transactions',
            element: <ResourcesTransactions/>
        },
        {
            path: '/groon/resources/transaction/:id',
            element: <ResourcesTransaction/>
        },

        // Financial Accounting
        {
            path: '/groon/resources/financial-accounting',
            element: <ResourcesFinancialAccounting/>
        },
        {
            path: '/groon/resources/financial-accounting/account/:accountId',
            element: <ResourcesFinancialAccount/>
        },

        // Time Management
        {
            path: '/groon/resources/time-tracking',
            element: <ResourcesTimeManagement/>
        },

        // Warehouse Management
        {
            path: '/groon/resources/warehouse-management',
            element: <ResourcesWarehousesIndex/>
        },
        {
            path: '/groon/resources/warehouse-management/warehouses',
            element: <ResourcesWarehouses/>
        },
        {
            path: '/groon/resources/warehouse-management/inventory',
            element: <ResourcesInventory/>
        },

        // TMS
        {
            path: '/groon/resources/products',
            element: <ResourcesProducts/>
        },
        {
            path: '/groon/resources/offer/:offerId?',
            element: <ResourcesOffer/>
        },
        {
            path: '/groon/resources/offers',
            element: <ResourcesOffers/>
        },
        {
            path: '/groon/resources/contracts',
            element: <ResourcesContracts/>
        },
        {
            path: '/groon/resources/invoices',
            element: <ResourcesInvoices/>
        },
        {
            path: '/groon/resources/invoice/:invoiceId?',
            element: <ResourcesInvoice/>
        },
        {
            path: '/groon/resources/product/:productId?',
            element: <ResourcesProduct/>
        },
        {
            path: '/groon/resources/orders',
            element: <ResourcesOrders/>
        },
        {
            path: '/groon/resources/order/:orderId?',
            element: <ResourcesOrder/>
        },
        {
            path: '/groon/resources/subscriptions',
            element: <ResourcesSubscriptions/>
        },
        {
            path: '/groon/resources/subscriptions/:subscriptionId',
            element: <ResourcesSubscription/>
        },
    ])

    // Spaces
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/spaces',
            element: <Navigate to='/groon/spaces/list'/>
        },
        {
            path: '/groon/spaces/list',
            element: <ProjectsList/>
        },
        {
            path: '/groon/spaces/view/:spaceId/:content?/:id?',
            element: <ProjectsProject/>
        },
        {
            path: '/groon/spaces/finished/:spaceId',
            element: <ProjectsFinished/>
        },
        {
            path: '/groon/spaces/add',
            element: <ProjectsCreate/>
        },
        {
            path: '/groon/spaces/browse',
            element: <ProjectsBrowse/>
        }
    ])

    // Website
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/website',
            element: <WebsiteIndex/>
        },

        // Content
        {
            path: '/groon/website/sites',
            element: <WebsiteSites/>
        },
        {
            path: '/groon/website/site/:siteId?',
            element: <WebsiteSite/>
        },
        {
            path: '/groon/website/articles',
            element: <WebsiteArticles/>
        },
        {
            path: '/groon/website/articles/:articleId?',
            element: <WebsiteArticle/>
        },

        // Visitors & Sessions
        {
            path: '/groon/website/visitors',
            element: <WebsiteVisitors/>
        },
        {
            path: '/groon/website/visitors/:visitorId?',
            element: <WebsiteVisitor/>
        },
        {
            path: '/groon/website/sessions',
            element: <WebsiteSessions/>
        },
        {
            path: '/groon/website/sessions/:sessionId?',
            element: <WebsiteSession/>
        },

        // Configuration
        {
            path: '/groon/website/configuration',
            element: <WebsiteConfiguration/>
        },
    ])

    // Workflows
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/workflows',
            element: <CoreWorkflows/>
        },
        {
            path: '/groon/workflows/:workflowId',
            element: <CoreWorkflow/>
        },
        {
            path: '/groon/workflow-executions/:executionId',
            element: <CoreWorkflowExecution/>
        },
    ])

    // Internal Routes
    routesConfig.push({
        path: '/groon',
        element: (
            <AuthGuard>
                <OmniaLayout>
                    <Suspense fallback={<LoadingScreen/>}>
                        <Outlet/>
                    </Suspense>
                </OmniaLayout>
            </AuthGuard>
        ),
        children: omniaRoutes
    });

    // External Routes (CRM)
    routesConfig.push({
        path: '/crm',
        element: (
            <GuestGuard>
                <AuthLayout>
                    <Suspense fallback={<LoadingScreen/>}>
                        <Outlet/>
                    </Suspense>
                </AuthLayout>
            </GuestGuard>
        ),
        children: [
            {
                path: '/crm/unsubscribe/:hash',
                element: <ConnectionsOptOut/>
            },
            {
                path: '/crm/opt_in/:hash',
                element: <ConnectionsOptIn/>
            }
        ]
    })

    // Custom Routes
    routesConfig = routesConfig.concat(customRoutes);

    // Fallback is CMS Entry Point FIXME: this will now always hit
    routesConfig.push({
        path: "/:url?/:identifier?",
        element: <WebsiteBuilder/>,
    });

    // If nothing else hits
    routesConfig.push({
        path: '*',
        element: <NotFound/>
    })

    // Return the final object
    return routesConfig;

}
