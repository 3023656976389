import {useSelector} from "react-redux";
import {useCallback, useRef} from "react";
import {useCurrentUser} from "./use-current-user";

export const useSecurityCheck = () => {

    const { user, type  } = useCurrentUser();
    const moduleData = useSelector(state => state.account.modules);
    const userRef = useRef();
    userRef.current = user;

    const isModuleInstalled = ( module ) => {

        // If no module is passed, return false
        if(!module)
            return false;

        // check if module is a key in moduleData, if not, raise an error
        // eslint-disable-next-line no-prototype-builtins
        if(!moduleData?.hasOwnProperty(module)){
            console.log('Module name not found in installedModuleData: ', module);
            return false;
        }

        // check if the module is installed
        return moduleData[module];
    }

    const hasPermissions = useCallback(( wantedPermissions = [], operation = "and" ) => {

        // Get permissions
        const permissions = user?.permissions || [];

        // If no permissions are needed, return true
        if(!wantedPermissions)
            return true;

        if(typeof(wantedPermissions) === "string"){

            // Simply check the passed permission
            return permissions.includes(wantedPermissions) || false;

        } else if(typeof(wantedPermissions) === "object"){

            // Check if there are any permissions at all
            if(wantedPermissions.length === 0)
                return true;

            // Check all passed permissions
            let successfulChecks = [];
            for (let i = 0; i < wantedPermissions.length; i++)
                if(permissions.includes(wantedPermissions[i]))
                    successfulChecks.push(wantedPermissions[i]);

            // Check the success of the operation
            if(operation.toLowerCase() === "or"){
                return successfulChecks.length > 0;
            } else if(operation.toLowerCase() === "and"){
                return successfulChecks.length === wantedPermissions.length;
            } else {
                return false;
            }
        } else {
            console.log('useSecurityCheck does not recognize the type of permissions to check')
            return false;
        }

    }, [user, type]);

    return { hasPermissions, isModuleInstalled };
}