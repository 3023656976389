import React, {createContext, useState, useMemo, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { produce } from "immer";
import {coreAppsConfig} from "../config";
import {APP_SETTING} from "../../setup";
import {useSecurityCheck} from "../hooks/use-security-check";
import {useSelector} from "react-redux";

export const AppModeContext = createContext();

export const AppModeProvider = ({ children }) => {

    const location = useLocation();

    const [isBackend, setIsBackend] = useState(location.pathname !== '/');
    const [isService, setIsService] = useState(false);
    const [service, setService] = useState(null);
    const [serviceTheme, setServiceTheme] = useState(null);
    const [backendTheme, setBackendTheme] = useState(null);
    const [websiteTheme, setWebsiteTheme] = useState(null);
    const [loadingElements, setLoadingElements] = useState([]);
    const [currentApp, setCurrentApp] = useState(null);
    const isWebsite = useMemo(() => !isService && !isBackend, [isService, isBackend]);
    const additionalApps = useMemo(() => APP_SETTING?.navigation || {}, []);
    const { hasPermissions } = useSecurityCheck();
    const user = useSelector(state => state.account.user);
    const allSections = useMemo(() => ({...coreAppsConfig, ...additionalApps}), [additionalApps]);
    const availableApps = useMemo(() => {
        return Object.values(allSections)
            ?.filter(s => s?.title && s?.path && s?.icon)
            ?.filter(s => hasPermissions(s?.permissions || []))
            ?.filter(a => a.path !== '/groon/home')
            ?.map(s => ({
                title: s.title,
                path: s.path,
                icon: s.icon
            }))
    }, [hasPermissions, allSections, user]);

    const layout = useMemo(() => {
        if(currentApp?.path?.includes('groon/home'))
            return 'flying';
        return 'vertical';
    }, [currentApp])

    const addLoadingElement = (element) => {
        setLoadingElements(prev => produce(prev, draft => {
            if (!draft.includes(element)) {
                draft.push(element);
            }
        }));
    }

    const removeLoadingElement = (element) => {
        setLoadingElements(prev => prev.filter(e => e !== element));
    }

    const loading = loadingElements.length > 0;

    const value = useMemo(() => ({
        loading,
        isService, isWebsite, isBackend, setIsService, setIsBackend,
        service, setService, currentApp, availableApps, layout,
        serviceTheme, setServiceTheme, backendTheme, setBackendTheme, websiteTheme, setWebsiteTheme,
        addLoadingElement, removeLoadingElement
    }), [
        loading, isService, isWebsite, isBackend, service, serviceTheme, backendTheme, websiteTheme, currentApp,
        availableApps, layout
    ]);

    useEffect(() => {
        const { pathname } = location;
        const pathSegments = pathname.split('/').filter(Boolean);

        if (pathSegments[0] === 'groon' && pathSegments[1]) {
            const matchedKey = Object.keys(allSections).find(appKey => {
                const configPath = allSections[appKey].path || '';
                const configSegments = configPath.replace(/^\/+/, '').split('/').filter(Boolean);
                return (
                    configSegments?.length >= 2 &&
                    configSegments[0] === 'groon' &&
                    configSegments[1] === pathSegments[1]
                );
            });

            setCurrentApp(matchedKey ? allSections[matchedKey] : null);
        } else {
            // If it doesn't even start with "groon/", set app to null
            setCurrentApp(null);
        }
    }, [location.pathname]);

    return (
        <AppModeContext.Provider value={value}>
            {children}
        </AppModeContext.Provider>
    );
};

export const AppModeConsumer = AppModeContext.Consumer;
