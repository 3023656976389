import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import useSidebarWidth from "../../hooks/use-sidebar-width";

const WobblingCircleBackground = ({show}) => {
    const theme = useTheme();
    const [isVisible, setIsVisible] = useState(false);
    const sideBarWidth = useSidebarWidth();

    useEffect(() => {
        // Trigger the fade-in effect after the component is mounted
        const timeout = setTimeout(() => setIsVisible(show), 1000); // 1 second delay

        return () => {
            // Fade out before unmounting
            setIsVisible(false);
            clearTimeout(timeout); // Clear timeout if component unmounts quickly
        };
    }, [show]);

    return (
        <Box
            id="wobble-background"
            sx={{
                position: 'fixed',  // Changed from 'absolute' to 'fixed'
                top: 0,
                left: sideBarWidth,
                width: 'calc(100vw - ' + sideBarWidth + 'px)',
                height: '100vh',
                overflow: 'hidden',
                zIndex: -1,
                opacity: isVisible ? 1 : 0, // Handle visibility
                transition: 'opacity 1s ease-in-out', // Fade in/out transition
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '1000px',
                    height: '1000px',
                    background: 'conic-gradient(#0041BE, #FF0024, #FFB001, #FF589C)',
                    borderRadius: '50%',
                    opacity: '0.5',
                    filter: 'blur(80px)',
                    animation: 'wobble 30s infinite cubic-bezier(0.65, 0, 0.35, 1)',
                    transformOrigin: 'center',
                    '@keyframes wobble': {
                        '0%': {
                            transform: 'translate(-50%, -50%) scale(1.5) rotate(0deg)',
                        },
                        '50%': {
                            transform: 'translate(-50%, -50%) scale(2) rotate(180deg)',
                        },
                        '100%': {
                            transform: 'translate(-50%, -50%) scale(1.5) rotate(360deg)',
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '500px',
                        height: '500px',
                        backgroundColor: theme.palette.background.default, // Access theme background color
                        borderRadius: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            </Box>
        </Box>
    );
};

export default WobblingCircleBackground;
