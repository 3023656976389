import React, {useEffect, useState} from "react";
import {
    Box,
    Card, CardActions,
    CardContent,
    CardHeader,
    Divider,
    Grid,
} from "@mui/material";
import useOmniaApi from "../../../omnia/hooks/use-omnia-api";
import {useNotifications} from "../../../omnia/hooks/use-notifications";
import {useTranslation} from "react-i18next";
import SettingsItems from "src/omnia/components/elements/settings/settings-items";
import SaveButton from "../../../omnia/components/elements/save-button";
import DetailViewLayout from "../../../omnia/components/general/pages/aios-view";

export default function SystemSettings() {

    const {get, post} = useOmniaApi();
    const {t} = useTranslation();
    const {notify} = useNotifications();
    const [settings, setSettings] = useState({});

    const setupFields = [
        {
            label: 'Registration Open',
            type: 'checkbox',
            description: 'We currently accept new project and visitors can open new GROON requests by hand',
            name: 'onboardingOpen',
        },
        {
            label: 'Onboarding Chat Length',
            type: 'number',
            description: 'The number of messages that the onboarding chat needs to have until the client is considered onboarded',
            name: 'onboardingMessageLimit',
        },
        {
            label: 'Onboarding System Message',
            type: 'text',
            description: 'The System Message provided to the GenAI model used in the onboarding chat with a new client',
            name: 'onboardingSystemMsg',
            multiline: true,
            rows: 8
        }
    ]

    const handleSave = () => {
        post('groon/systems/setup', settings).then((result) => {
            notify(t('Success'), 'success');
        })
    }

    const handleChange = (event) => {
        const {name, value} = event.target;
        setSettings(prev => ({
            ...prev,
            [name]: value
        }))
    }

    useEffect(() => {
        get('groon/systems/setup').then((result) => {
            setSettings(result);
        })
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={t("Settings")}
                        subheader={t('Manage the global system and onboarding setup')}
                    />
                    <Divider/>
                    <CardContent>
                        <SettingsItems
                            settings={settings}
                            handleChange={handleChange}
                            fields={setupFields}
                        />
                    </CardContent>
                    <CardActions>
                        <SaveButton onClick={handleSave}/>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
    
}