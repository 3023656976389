import React from "react";
import {useTranslation} from "react-i18next";
import {List} from "@mui/material";
import {SideNavItem} from "../../layouts/aios/flying-layout/side-nav-item";
import CreateModelDialog from "./models-training/create-model-dialog";
import {useDialog} from "../../../hooks/use-dialog";
import {useLocation} from "react-router-dom";
import AiModelStateIndicator from "./ai-model-state-indicator";
import usePagination from "../../../hooks/use-pagination";
import {useSecurityCheck} from "../../../hooks/use-security-check";

export default function ModelsSidebarSection(){
    // TODO: If we have a lot of models we should pivot to a "models" button

    const { t } = useTranslation();
    const location = useLocation();
    const addModelDialog = useDialog();
    const { hasPermissions } = useSecurityCheck();
    const elementLoader = usePagination({
        endpoint: 'ai/ai_models',
        query: {ordering: 'created_at'},
        page: 1,
        pageSize: 500
    })

    const handleNewModels = (newModels) => {
        elementLoader.setElements(prev => [...prev, ...newModels]);
    }

    const getIcon = (model) => {
        // TODO: image, text and star icon for models
        return 'Star06'
    }

    return (
        <>
            <List sx={{py: 0}}>
                {elementLoader.loading ? (
                    <SideNavItem
                        title={t('common.loading')}
                        icon="Star06"
                        disabled={true}
                    />
                ) : elementLoader.elements?.map(model => (
                    <SideNavItem
                        title={model.name}
                        icon={getIcon(model)}
                        active={location.pathname === `/groon/ai-studio/models/${model.id}`}
                        path={`/groon/ai-studio/models/${model.id}`}
                        chip={(
                            <AiModelStateIndicator model={model} size="small" />
                        )}
                    />
                ))}
                {hasPermissions('create_model') && (
                    <SideNavItem
                        title={t('intelligence.models.add_model')}
                        icon="PlusCircle"
                        onClick={addModelDialog.handleOpen}
                    />
                )}
            </List>
            <CreateModelDialog
                onClose={addModelDialog.handleClose}
                open={addModelDialog.open}
                onAdded={handleNewModels}
            />
        </>
    )

}