import * as React from 'react';
import Box from '@mui/material/Box';
import { Link } from '@tiptap/extension-link';
import { Placeholder } from '@tiptap/extension-placeholder';
import {EditorContent, generateHTML, useEditor} from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import PropTypes from "prop-types";
import { TextEditorToolbar } from './text-editor-toolbar';
import { useTheme } from "@mui/system";
import { getHtmlWrapperStyles } from "../html-wrapper";
import { AppBar, Container, Dialog, DialogContent, IconButton, Stack, Typography } from "@mui/material";

const EditorBox = (props) => {
    const {
        content,
        editable = true,
        hideToolbar,
        noBorder = false,
        autoFocus = false,
        onUpdate = () => {},
        onMarked = () => {},
        sx = {},
        placeholder,
        maximized = false,
        onMaximizedChange = () => {},
        allowChangesIfFocused = false
    } = props;

    const theme = useTheme();
    const extensions = [
        StarterKit,
        Placeholder.configure({ emptyEditorClass: 'is-editor-empty', placeholder }),
        Link.configure({ openOnClick: false, autolink: true }),
    ];

    const editor = useEditor({
        extensions,
        content,
        editable,
        onUpdate,

        onSelectionUpdate: ({ editor }) => {
            const { from, to } = editor.state.selection;
            // If some text is selected, extract it. Otherwise, send an empty string.
            const selectedText = from !== to ? editor.state.doc.textBetween(from, to, ' ') : '';
            onMarked(selectedText);
        },

    }, []);

    React.useEffect(() => {
        if (!editor) return;

        // Only update if the editor is not focused and content differs.
        if (allowChangesIfFocused || !editor.isFocused) {
            if (content !== editor.getHTML()) {
                editor.commands.setContent(content);
            }
        }

    }, [editor, content]);

    return (
        <Box
            className="tiptap-root"
            sx={{
                ...sx,
                ...(maximized ? { height: '100%', maxHeight: '100%' } : {}),
                ...{
                    display: 'flex',
                    flexDirection: 'column',
                    ...(editable && {
                        border: noBorder ? 'none' : `1px solid ${theme.palette.divider}`,
                        borderRadius: noBorder ? 0 : 1,
                    }),
                    '& .tiptap-container': {
                        display: 'flex',
                        flex: '1 1 auto',
                        flexDirection: 'column',
                        height: maximized ? '100vh' : 'auto',
                        minHeight: 0,
                        '& h1, & h2, & h3, & h4': {
                            marginTop: '0 !important',
                            marginBottom: '0 !important',
                            lineHeight: '2',
                        },
                        '& p, & ol, & ul': {
                            marginBlockEnd: '0 !important',
                            marginBlockStart: '0 !important',
                            lineHeight: '1.5',
                        },
                    },
                    '& .tiptap': {
                        ...getHtmlWrapperStyles(theme),
                        flex: '1 1 auto',
                        overflow: 'auto',
                        p: '6px 12px',
                        '&:focus-visible': { outline: 'none' },
                        '&.resize-cursor': {
                            cursor: 'ew-resize',
                            '& table': { cursor: 'col-resize' },
                        },
                        '& .is-editor-empty:before': {
                            color: theme.palette.text.secondary,
                            content: 'attr(data-placeholder)',
                            float: 'left',
                            height: 0,
                            pointerEvents: 'none',
                        },
                    },
                }
            }}
        >
            {!hideToolbar && (
                <TextEditorToolbar
                    isMaximized={maximized}
                    onToggleMaximize={onMaximizedChange}
                    editor={editor}
                />
            )}
            <EditorContent
                className="tiptap-container"
                editor={editor}
                autoFocus={autoFocus}
            />
        </Box>
    );
};

export function TextEditor({ noBorder, autoFocus = false, disableMaximize = false, onMarked, ...rest }) {
    const [maximized, setMaximized] = React.useState(false);

    const handleToggleMaximize = () => {
        setMaximized(!maximized);
    };

    return (
        <>
            <Dialog open={maximized} onClose={handleToggleMaximize} fullScreen>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <Container maxWidth="md" sx={{ flex: '1 1 auto', overflow: 'auto' }}>
                        <EditorBox
                            maximized={true}
                            onMaximizedChange={handleToggleMaximize}
                            noBorder={false}
                            autoFocus={autoFocus}
                            onMarked={onMarked}
                            {...rest}
                        />
                    </Container>
                </DialogContent>
            </Dialog>
            <EditorBox
                maximized={false}
                onMaximizedChange={disableMaximize ? null : handleToggleMaximize}
                noBorder={noBorder}
                autoFocus={autoFocus}
                onMarked={onMarked}
                {...rest}
            />
        </>
    );
}

TextEditor.propTypes = {
    content: PropTypes.string,
    editable: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    noBorder: PropTypes.bool,
    onUpdate: PropTypes.func,
    placeholder: PropTypes.string,
    sx: PropTypes.object,
    onMarked: PropTypes.func, // New prop to handle marked text changes
};

export default TextEditor;
