import React from "react";
import {Button, IconButton, MenuItem, Stack, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useNotifications} from "../../../hooks/use-notifications";
import {useNavigate} from "react-router-dom";
import OnIcon from "../icon";

function RemoveSharableObject(props){

    const {
        entity,
        endpoint,
        onRemove = null,
        removeTooltip = null,
        variant = 'menu-item',
        noRedirect = false,
        removeLabel = null,
        removeRight = [],
        disabled = false,
        withIcon = false,
        tooltipPlacement = 'left',
        ...rest
    } = props;

    const { t } = useTranslation();
    const { hasPermissions } = useSecurityCheck();
    const { del } = useOmniaApi();
    const { notifySuccess } = useNotifications();
    const navigate = useNavigate();

    const handleRemove = (event) => {
        if(event){
            event.preventDefault();
            event.stopPropagation();
        }
        if(window.confirm(t('notify.are_you_sure'))){
            del(endpoint, entity?.id).then(() => {
                notifySuccess(t);
                if(!noRedirect)
                    navigate(entity?.entity_link || '/groon');
                onRemove?.(entity?.id);
            });
        }
    }

    // FIXME: what about public?

    if(!entity)
        return null;

    return (
        <>
            {!entity?.is_owner ? (
                <Tooltip title={t('common.tooltips.stop_sharing_tooltip', {name: entity?.object_name})} placement={tooltipPlacement}>
                    {variant === 'button' && (
                        <Button
                            color="error"
                            variant="outlined"
                            onClick={handleRemove}
                            startIcon={withIcon && <OnIcon iconName="Trash01" size="small" />}
                            {...rest}
                        >
                            {t('common.stop_sharing')}
                        </Button>
                    )}
                    {variant === 'menu-item' && (
                        <MenuItem onClick={handleRemove} disabled={disabled} {...rest}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {withIcon && (
                                    <OnIcon iconName="Trash01" size="small" color="error" />
                                )}
                                <Typography color="error">
                                    {t('common.stop_sharing')}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    )}
                    {variant === 'icon-button' && (
                        <IconButton color="error" variant={variant} onClick={handleRemove} {...rest}>
                            <OnIcon iconName="Trash01" color="error" />
                        </IconButton>
                    )}
                </Tooltip>
            ) : (
                <Tooltip title={t(removeTooltip || 'common.tooltips.archive_object_tooltip', {name: entity?.object_name})} placement={tooltipPlacement}>
                    {variant === 'button' && (
                        <Button
                            color="error"
                            variant="outlined"
                            onClick={handleRemove}
                            disabled={!hasPermissions(removeRight) || disabled}
                            startIcon={withIcon && <OnIcon iconName="Archive" />}
                            {...rest}
                        >
                            {t(removeLabel || 'common.archive_object', {name: entity?.entity_name})}
                        </Button>
                    )}
                    {variant === 'menu-item' && (
                        <MenuItem onClick={handleRemove} disabled={!hasPermissions(removeRight) || disabled} {...rest}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {withIcon && (
                                    <OnIcon iconName="Archive" size="small" color="error" />
                                )}
                                <Typography color="error">
                                    {t(removeLabel || 'common.archive_object', {name: entity?.entity_name})}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    )}
                    {variant === 'icon-button' && (
                        <IconButton color="error" variant={variant} disabled={!hasPermissions(removeRight) || disabled} onClick={handleRemove} {...rest}>
                            <OnIcon iconName="Archive" color="error" />
                        </IconButton>
                    )}
                </Tooltip>
            )}
        </>
    )

}

RemoveSharableObject.propTypes = {
    entity: PropTypes.object.isRequired,
    endpoint: PropTypes.string.isRequired,
    removeTooltip: PropTypes.string,
    removeLabel: PropTypes.string,
    removeRight: PropTypes.any,
    onRemove: PropTypes.func,
    disabled: PropTypes.bool,
    withIcon: PropTypes.bool,
    noRedirect: PropTypes.bool,
    tooltipPlacement: PropTypes.string,
    variant: PropTypes.oneOf(['button', 'menu-item', 'icon-button'])
}

export default RemoveSharableObject;