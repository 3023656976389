import {useSelector} from "react-redux";


export function useCurrentUser() {
    // Select both user types from Redux state
    const accountUser = useSelector(state => state.account?.user);
    const serviceUser = useSelector(state => state.service?.user);

    // Determine the user and its type
    const user = accountUser || serviceUser;
    const type = accountUser ? "user" : serviceUser ? "contact" : null;

    return { user, type };
}