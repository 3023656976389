import React from 'react';
import Fade from '@mui/material/Fade';
import PropTypes from 'prop-types';
import {AppBar, Toolbar, IconButton, Typography, DialogContent, Dialog, Stack} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade in ref={ref} {...props} />;
});

function FullscreenWrapper({ title, fullscreen, open, actions, closeCallback, ...rest }){

    const theme = useTheme();

    return (
        <Dialog
            fullScreen={fullscreen}
            disableEnforceFocus
            fullWidth
            maxWidth="lg"
            open={open}
            TransitionComponent={Transition}
            onClose={closeCallback}
            {...rest}
        >
            <AppBar style={{position: 'relative', backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary}} >
                <Toolbar>
                    {closeCallback && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={closeCallback}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" sx={{ml: 2}} noWrap style={{flex: 1}} >
                        {title}
                    </Typography>
                    <Stack direction="row" spacing={0}>
                        {actions.map((action, i) => (
                            <div color="inherit" key={'action_' + i} onClick={action['action']}>
                                {action['name']}
                            </div>
                        ))}
                    </Stack>
                </Toolbar>
            </AppBar>
            <DialogContent sx={{backgroundColor: theme.palette.background.default}}>
                {rest.children}
            </DialogContent>
        </Dialog>
    );
}

FullscreenWrapper.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    fullscreen: PropTypes.bool,
    closeCallback: PropTypes.func.isRequired,
    actions: PropTypes.array.isRequired
};

FullscreenWrapper.prototype = {
    fullscreen: false,
}

export default FullscreenWrapper;