import React from "react";
import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, Stack, Tab, Tabs} from "@mui/material";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import useTabs from "../../../hooks/use-tabs";
import {useTranslation} from "react-i18next";
import UserTable from "./connect/organization/user-table";
import GroupsTable from "./connect/organization/groups-table";
import GroupModal from "./connect/organization/add-group-dialog";
import UserModal from "./connect/organization/add-user-dialog";
import {useDialog} from "../../../hooks/use-dialog";
import OnIcon from "../../elements/icon";
import DataGraph from "../../elements/data-graph";

function UsersAndGroups(){

    const {hasPermissions, isModuleInstalled} = useSecurityCheck();
    const { t } = useTranslation();
    const { tab, handleTabChange } = useTabs('users');
    const addGroupDialog = useDialog();
    const addUserDialog = useDialog();

    const handleNew = () => {
        if(tab === 'users'){
            addUserDialog.handleOpen();
        } else {
            addGroupDialog.handleOpen();
        }
    }

    return (
        <Card>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" sx={{width: '100%', px: 2}} justifyContent="space-between">
                        <Tabs
                            onChange={handleTabChange}
                            scrollButtons="auto"
                            textColor="secondary"
                            value={tab}
                            variant="scrollable"
                        >
                            {hasPermissions("manage_users") && (
                                <Tab label={t('core.users')} value='users'/>
                            )}
                            {hasPermissions("manage_organization") && (
                                <Tab label={t('core.groups')} value='groups'/>
                            )}
                        </Tabs>
                        <Button startIcon={<OnIcon iconName="Plus" size="small" />} variant="contained" onClick={handleNew}>
                            {t('common.new')}
                        </Button>
                    </Stack>
                    <Divider/>
                </Grid>
                {(tab === 'users' && hasPermissions("manage_users")) && (
                    <Grid item sm={12} xs={12}>
                        <UserTable noCard noBorder />
                    </Grid>
                )}
                {(tab === 'groups' && hasPermissions("manage_organization")) && (
                    <Grid item sm={12} xs={12}>
                        <GroupsTable noCard noBorder />
                    </Grid>
                )}
                {hasPermissions("manage_organization") && (
                    <GroupModal open={addGroupDialog.open} handleClose={addGroupDialog.handleClose} />
                )}
                {hasPermissions("manage_users") && (
                    <UserModal open={addUserDialog.open} handleClose={addUserDialog.handleClose} />
                )}
            </Grid>
        </Card>
    )
}

export default UsersAndGroups;