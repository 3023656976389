import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box, ButtonBase,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {Scrollbar} from 'src/omnia/components/elements/scrollbar';
import {usePathname} from 'src/omnia/hooks/use-pathname';
import CreateUserRequest from "../../../modules/home/create-user-request";
import {SideNavSection} from "../flying-layout/side-nav-section";
import {useSettings} from "../../../../hooks/use-settings";
import {TenantSwitch} from "../tenant-switch";
import {useAppMode} from "../../../../hooks/use-app-mode";
import {FlyingNav} from "../flying-nav";
import OnIcon from "../../../elements/icon";
import {useNavigate} from "react-router-dom";

const MOBILE_NAV_WIDTH = 230;

const useCssVars = (color) => {
    const theme = useTheme();

    return useMemo(() => {
        switch (color) {
            // Blend-in and discreet have no difference on mobile because
            // there's a backdrop and differences are not visible
            case 'blend-in':
            case 'discreet':
                if (theme.palette.mode === 'dark') {
                    return {
                        '--nav-bg': theme.palette.background.default,
                        '--nav-color': theme.palette.neutral[100],
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[400],
                        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-color': theme.palette.text.primary,
                        '--nav-item-disabled-color': theme.palette.neutral[600],
                        '--nav-item-icon-color': theme.palette.neutral[500],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[700],
                        '--nav-item-chevron-color': theme.palette.neutral[700],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    };
                } else {
                    return {
                        '--nav-bg': theme.palette.background.default,
                        '--nav-color': theme.palette.text.primary,
                        '--nav-logo-border': theme.palette.neutral[100],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.text.secondary,
                        '--nav-item-hover-bg': theme.palette.action.hover,
                        '--nav-item-active-bg': theme.palette.action.selected,
                        '--nav-item-active-color': theme.palette.text.primary,
                        '--nav-item-disabled-color': theme.palette.neutral[400],
                        '--nav-item-icon-color': theme.palette.neutral[400],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[400],
                        '--nav-item-chevron-color': theme.palette.neutral[400],
                        '--nav-scrollbar-color': theme.palette.neutral[900]
                    };
                }
            case 'evident':
                if (theme.palette.mode === 'dark') {
                    return {
                        '--nav-bg': theme.palette.neutral[800],
                        '--nav-color': theme.palette.common.white,
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[400],
                        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-color': theme.palette.common.white,
                        '--nav-item-disabled-color': theme.palette.neutral[500],
                        '--nav-item-icon-color': theme.palette.neutral[400],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[500],
                        '--nav-item-chevron-color': theme.palette.neutral[600],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    };
                } else {
                    return {
                        '--nav-bg': theme.palette.neutral[800],
                        '--nav-color': theme.palette.common.white,
                        '--nav-logo-border': theme.palette.neutral[700],
                        '--nav-section-title-color': theme.palette.neutral[400],
                        '--nav-item-color': theme.palette.neutral[400],
                        '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
                        '--nav-item-active-color': theme.palette.common.white,
                        '--nav-item-disabled-color': theme.palette.neutral[500],
                        '--nav-item-icon-color': theme.palette.neutral[400],
                        '--nav-item-icon-active-color': theme.palette.primary.main,
                        '--nav-item-icon-disabled-color': theme.palette.neutral[500],
                        '--nav-item-chevron-color': theme.palette.neutral[600],
                        '--nav-scrollbar-color': theme.palette.neutral[400]
                    };
                }
            default:
                return {};
        }
    }, [theme, color]);
};

export const MobileNav = (props) => {
    const { color = 'evident', open, onClose, sections = [] } = props;
    const pathname = usePathname();
    const navigate = useNavigate();
    const appMode = useAppMode();
    const cssVars = useCssVars(color);

    const handleOpen = (path) => {
        navigate(path);
        onClose();
    }

    const visionLayout = appMode.layout !== 'vertical';

    if(visionLayout)
        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen={true}
            >
                <DialogTitle>
                    <Stack direction="row-reverse">
                        <IconButton onClick={onClose}>
                            <OnIcon iconName="XClose" />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start
                        " sx={{height: '90%'}}
                        spacing={2}
                    >
                        {sections?.map((section, index) => (
                            <>
                                {section?.path ? (
                                    <ButtonBase key={index} sx={{width: '100%'}} onClick={() => handleOpen(section?.path)}>
                                        <Stack sx={{width: '100%'}} spacing={2} direction="row" alignItems="center" justifyContent="flex-start">
                                            <Avatar sx={{ width: 40, height: 40 }}>
                                                <OnIcon iconName={section.icon} />
                                            </Avatar>
                                            <Typography variant="h6">{section.title}</Typography>
                                        </Stack>
                                    </ButtonBase>
                                ) : (
                                    <>
                                        {/* TODO: we need to take care of superCustomActions here */}
                                    </>
                                )}
                            </>
                        ))}
                    </Stack>
                </DialogContent>
            </Dialog>
        );

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    ...cssVars,
                    backgroundColor: 'var(--nav-bg)',
                    color: 'var(--nav-color)',
                    margin: 0,
                    maxHeight: '100%',
                    width: MOBILE_NAV_WIDTH,
                    borderRadius: 0,
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                    overflow: 'hidden',
                },
            }}
            variant="temporary"
        >
            <Scrollbar
                sx={{
                    height: '100%',
                    '& .simplebar-content': {
                        height: '100%',
                    },
                    '& .simplebar-scrollbar:before': {
                        background: 'var(--nav-scrollbar-color)',
                    },
                }}
            >
                <Stack sx={{ height: '100%' }}>
                    <Box px={2} py={2}>
                        <TenantSwitch />
                    </Box>
                    <Stack
                        component="nav"
                        spacing={2}
                        sx={{
                            flexGrow: 1,
                            px: 2,
                        }}
                    >
                        {sections.map((section, index) => (
                            <>
                                {section?.isDivider ? (
                                    <Divider sx={{my: 2 }} />
                                ) : (
                                    <SideNavSection
                                        items={section.items}
                                        key={index}
                                        index={index}
                                        pathname={pathname}
                                        title={section.title}
                                        superCustomAction={section?.superCustomAction}
                                        component={section?.component}
                                    />
                                )}
                            </>
                        ))}
                    </Stack>
                </Stack>
            </Scrollbar>
        </Drawer>
    );
};

MobileNav.propTypes = {
    color: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
    onClose: PropTypes.func,
    open: PropTypes.bool,
    sections: PropTypes.array
};
