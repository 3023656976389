import React from "react";
import PropTypes from 'prop-types';
import {useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import {MobileNav} from '../mobile-nav';
import {SideNav} from './side-nav';
import {TopNav} from './top-nav';
import {useMobileNav} from './use-mobile-nav';
import ErrorHandler from "../../../elements/error-handler";
import {useSelector} from "react-redux";

const SIDE_NAV_WIDTH = 250;

const VerticalLayoutRoot = styled('div')(({theme, collapsed}) => {
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return {
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        ...((!lgUp || (lgUp && !collapsed)) ? {paddingLeft: SIDE_NAV_WIDTH} : {}),
    }
});

const VerticalLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

export const VerticalLayout = (props) => {
    const {children, sections, actionButton = null, tenantSwitch = null, menuItems, navColor} = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const mobileNav = useMobileNav();
    const collapseNavbar = useSelector(state => state.service.collapseNavbar);

    return (
        <>
            <TopNav
                onMobileNavOpen={mobileNav.handleOpen}
                menuItems={menuItems}
            />
            {(lgUp && !collapseNavbar) && (
                <SideNav
                    color={navColor}
                    sections={sections}
                    actionButton={actionButton}
                    tenantSwitch={tenantSwitch}
                />
            )}
            {!lgUp && (
                <MobileNav
                    tenantSwitch={tenantSwitch}
                    actionButton={actionButton}
                    color={navColor}
                    onClose={mobileNav.handleClose}
                    open={mobileNav.open}
                    sections={sections}
                />
            )}
            <VerticalLayoutRoot collapsed={collapseNavbar}>
                <VerticalLayoutContainer>
                    <ErrorHandler>
                        {children}
                    </ErrorHandler>
                </VerticalLayoutContainer>
            </VerticalLayoutRoot>
        </>
    );
};

VerticalLayout.propTypes = {
    children: PropTypes.node,
    navColor: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
    sections: PropTypes.array.isRequired,
    menuItems: PropTypes.array.isRequired,
    actionButton: PropTypes.node,
    tenantSwitch: PropTypes.node
};
