import {
    SET_UNREAD_NOTIFICATIONS,
    SET_USER,
    SET_LOADING_USER,
    SET_COLLAPSED_NAVBAR
} from 'src/omnia/store/actions/service-actions';
import { produce } from "immer";

const initialState = {
    unreadNotifications: 0,
    user: null,
    loadingUser: true,

    // Layout related
    collapseNavbar: false,
};

const serviceReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_COLLAPSED_NAVBAR: {
            console.log(action);
            return produce(state, draft => {
                draft.collapseNavbar = action.payload;
            })
        }

        case SET_USER:
            return produce(state, draft => {
                draft.user = action.payload;
            })

        case SET_UNREAD_NOTIFICATIONS:
            return produce(state, draft => {
                draft.unreadNotifications = action.payload;
            })

        case SET_LOADING_USER:
            return produce(state, draft => {
                draft.loadingUser = action.payload;
            })

        default: {
            return state;
        }
    }

};

export default serviceReducer;
