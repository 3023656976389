import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Divider, Grow,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSecurityCheck } from '../../../../hooks/use-security-check';
import OnIcon from '../../../elements/icon';
import { useIsMobile } from '../../../../hooks/use-is-mobile';
import usePerformTransition from "../../../../hooks/use-perform-transition";
import {useTheme} from "@mui/system";
import {useAppMode} from "../../../../hooks/use-app-mode";
import UserStatusSelector from "../../../modules/home/user/user-status-selector";
import {logout} from "../../../../store/actions/account-actions";
import UserMuteNotifications from "../../../modules/home/user/user-mute-notifications";
import UserHeader from "../../../modules/home/user/user-header";
import Fade from "@mui/material/Fade";
import {APP_SETTING} from "../../../../../setup";

export const AccountPopover = ({popover}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { hasPermissions } = useSecurityCheck();
    const appMode = useAppMode();
    const handleLogout = usePerformTransition(() => {
        dispatch(logout());
        navigate('/login');
    })
    const transit = usePerformTransition((path) => {
        navigate(path);
    })
    const { isFlying } = useIsMobile();
    const { t } = useTranslation();

    const handleOpen = (path) => {
        // Check whether we are currently home
        const isHome = appMode?.currentApp?.title === 'layout.home.title';

        // Close the popover
        popover.handleClose();

        // Navigate the user
        if(isHome){
            navigate(path);
        } else {
            transit(path);
        }
    }

    return (
        <Popover
            anchorEl={popover.anchorRef.current}
            anchorOrigin={isFlying ? {
                vertical: 'top',
                horizontal: 'left',
            } : {
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={isFlying ? {
                vertical: 'top',
                horizontal: 'right',
            } : {
                vertical: 'top',
                horizontal: 'right',
            }}
            disableScrollLock
            // TransitionComponent={Grow}
            onClose={popover.handleClose}
            open={popover.open}
            slotProps={{
                paper: {
                    sx: {borderRadius: theme?.config?.card_radius + "px", width: 220},
                    style: {transform: isFlying ? 'translateX(-15px)' : 'translateY(10px)'}
                },
            }}
        >
            <UserHeader sx={{p: 1.5}} tooltipPlacement="left" />
            <Divider />
            <Box sx={{ p: 1 }}>
                <UserStatusSelector />
                <UserMuteNotifications />
            </Box>
            <Divider />
            <Box sx={{ p: 1 }}>
                {hasPermissions('social_intranet') && (
                    <Tooltip enterDelay={1000} title={t('common.tooltips.profile')} placement="left">
                        <ListItemButton
                            onClick={() => handleOpen('/groon/home/profile')}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5
                            }}
                        >
                            <ListItemIcon>
                                <OnIcon iconName="User01" size="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1">{t('layout.profile')}</Typography>
                                }
                            />
                        </ListItemButton>
                    </Tooltip>
                )}
                {hasPermissions('manage_assistants') && (
                    <Tooltip enterDelay={1000} title={t('common.tooltips.assistants')} placement="left">
                        <ListItemButton
                            onClick={() => handleOpen('/groon/home/assistants')}
                            sx={{
                                borderRadius: 1,
                                px: 1,
                                py: 0.5
                            }}
                        >
                            <ListItemIcon>
                                <OnIcon iconName="MessageSmileCircle" size="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="body1">{t('layout.assistants')}</Typography>
                                }
                            />
                        </ListItemButton>
                    </Tooltip>
                )}
                <Tooltip enterDelay={1000} title={t('common.tooltips.integrations')} placement="left">
                    <ListItemButton
                        onClick={() => handleOpen('/groon/home/settings#ai-integrations')}
                        sx={{
                            borderRadius: 1,
                            px: 1,
                            py: 0.5
                        }}
                    >
                        <ListItemIcon>
                            <SvgIcon fontSize="small">
                                <OnIcon iconName="Link04" />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="body1">
                                    {t('layout.integrations')}
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </Tooltip>
                <Tooltip enterDelay={1000} title={t('common.tooltips.configuration')} placement="left">
                    <ListItemButton
                        onClick={() => handleOpen('/groon/home/settings#profile-general')}
                        sx={{
                            borderRadius: 1,
                            px: 1,
                            py: 0.5
                        }}
                    >
                        <ListItemIcon>
                            <OnIcon iconName="Settings02" size="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="body1">
                                    {t('layout.configuration')}
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </Tooltip>
            </Box>
            <Divider sx={{ my: '0 !important' }} />
            <Box p={1}>
                <Tooltip enterDelay={1000} title={t('common.tooltips.archive')} placement="left">
                    <ListItemButton
                        onClick={() => handleOpen('/groon/home/archive')}
                        sx={{
                            borderRadius: 1,
                            px: 1,
                            py: 0.5
                        }}
                    >
                        <ListItemIcon>
                            <SvgIcon fontSize="small">
                                <OnIcon iconName="Trash01" />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="body1">
                                    {t('core.archive')}
                                </Typography>
                            }
                        />
                    </ListItemButton>
                </Tooltip>
                <ListItemButton
                    onClick={handleLogout}
                    sx={{
                        borderRadius: 1,
                        px: 1,
                        py: 0.5
                    }}
                >
                    <ListItemIcon>
                        <SvgIcon fontSize="small">
                            <OnIcon iconName="LogOut03" />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1">
                                {t('layout.logout')}
                            </Typography>
                        }
                    />
                </ListItemButton>
            </Box>
        </Popover>
    )

};

AccountPopover.propTypes = {
    popover: PropTypes.object,
};
