import React from "react";
import SearchDialog from "../../elements/search-dialog";
import usePopover from "../../../hooks/use-popover";
import ModelsSidebarSection from "../../modules/ai-studio/models-sidebar-section";
import DashboardsSidebarSection from "../../modules/analytics/dashboards-sidebar-section";
import ContextsSidebarSection from "../../modules/connections/context/contexts-sidebar-section";
import CampaignsSidebarSection from "../../modules/marketing/campaigns/campaigns-sidebar-list";
import SpacesSidebarSection from "../../modules/spaces/spaces-sidebar-list";
import SitesSidebarSection from "../../modules/website/sites-sidebar-list";
import ToHomeButton from "./vertical-layout/to-home-button";
import {useTranslation} from "react-i18next";
import {Box, ButtonBase, Typography} from "@mui/material";
import OnIcon from "../../elements/icon";
import PropTypes from "prop-types";

const SearchButton = ({ depth, offset, sx = {}, ...rest }) => {
    const { t } = useTranslation();

    return (
        <ButtonBase
            sx={{
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                pl: `${(depth === 0 ? 10 : 42) + offset}px`,
                pr: '14px',
                py: '8px',
                width: '100%',
                textAlign: 'left',
                backgroundColor: 'var(--nav-item-hover-bg)',
                '&:hover': {
                    backgroundColor: 'var(--nav-item-active-bg)'
                },
                ...sx
            }}
            {...rest}
        >
            <Box
                component="span"
                sx={{
                    alignItems: 'center',
                    color: 'var(--nav-item-icon-color)',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    mr: 2,
                }}
            >
                <OnIcon iconName="SearchMd" size="small" />
            </Box>
            <Box
                component="span"
                sx={{
                    color: 'var(--nav-item-icon-color)',
                    flexGrow: 1,
                    fontFamily: (theme) => theme.typography.fontFamily,
                    fontWeight: depth > 0 ? 400 : 600,
                    whiteSpace: 'nowrap',
                }}
            >
                <Typography
                    variant={depth > 0 ? "body2" : "body1"}
                    sx={{ fontWeight: depth > 0 ? 400 : 600, lineHeight: 1 }}
                >
                    {t('common.search')}
                </Typography>
            </Box>
            <Box
                component="span"
                sx={{
                    color: 'var(--nav-item-icon-color)',
                    display: 'flex',
                    alignItems: 'center',
                    ml: 2
                }}
            >
                <Typography variant="body2" sx={{ mr: 0.5 }}>⌘</Typography>
                <Typography variant="body2">K</Typography>
            </Box>
        </ButtonBase>
    );
};

function CustomSidebarItems(props){

    const { superCustomAction, depth } = props;

    const popover = usePopover();
    const offset = depth === 0 ? 0 : (depth - 1) * 16;

    if(superCustomAction === 'search')
        return (
            <>
                <SearchDialog popover={popover}/>
                <SearchButton
                    depth={depth}
                    offset={offset}
                    onClick={popover.handleOpen}
                />
            </>
        )

    if(superCustomAction === 'ai-model-list')
        return (
            <ModelsSidebarSection />
        )

    if(superCustomAction === 'dashboard-list')
        return (
            <DashboardsSidebarSection />
        )

    if(superCustomAction === 'contexts-list')
        return (
            <ContextsSidebarSection />
        )

    if(superCustomAction === 'campaigns-list')
        return (
            <CampaignsSidebarSection />
        )

    if(superCustomAction === 'spaces-list')
        return (
            <SpacesSidebarSection />
        )

    if(superCustomAction === 'sites-list')
        return (
            <SitesSidebarSection />
        )

    if(superCustomAction === 'to-home')
        return (
            <ToHomeButton {...props} />
        )

    return null;
}

SearchButton.propTypes = {
    depth: PropTypes.number,
    offset: PropTypes.number,
    sx: PropTypes.object
};

CustomSidebarItems.propTypes = {
    superCustomAction: PropTypes.string,
    depth: PropTypes.number
}

export default CustomSidebarItems;