/* eslint-disable react/no-array-index-key */
import React, {memo, lazy, Suspense} from 'react';
import {mergeRoutes} from "src/omnia/routes-composer";
import { Layout as PlainLayout } from "src/omnia/components/layouts/plain/default-layout";
import { Layout as OmniaLayout } from "src/omnia/components/layouts/aios/index";
import { ServiceLayout } from "./omnia/components/layouts/service";
import AuthGuard from "./omnia/components/general/auth/AuthGuard";
import ServiceGuard from "./omnia/components/general/auth/ServiceGuard";
import GuestGuard from "./omnia/components/general/auth/GuestGuard";
import {Outlet} from "react-router";
import LoadingScreen from "./omnia/components/general/loading-screen";
import OnIcon from "./omnia/components/elements/icon";

// Systems
const SignUpViewComponent = lazy(() => import('src/views/systems/sign-up-view'));
const SystemsViewComponent = lazy(() => import('src/views/systems'));
const OmniaSystemComponent = lazy(() => import('src/views/systems/system'));
const ServerComponent = lazy(() => import('src/views/systems/server'));
const CloudViewComponent = lazy(() => import('src/views/systems/cloud'));

// Teasers
const TeaserFileSearchIndexComponent = lazy(() => import('src/views/teasers/filesearch/index'));
const TeaserFileSearchFilesComponent = lazy(() => import('src/views/teasers/filesearch/files'));
const TeaserFileSearchSettingsComponent = lazy(() => import('src/views/teasers/filesearch/settings'));

export const getRoutes = () => {
    return mergeRoutes([
        {
            path: '/get-started',
            element: (
                <GuestGuard>
                    <PlainLayout>
                        <Suspense fallback={<LoadingScreen inScreen={true} /> }>
                            <Outlet/>
                        </Suspense>
                    </PlainLayout>
                </GuestGuard>
            ),
            children: [
                {
                    path: '/get-started',
                    element: <SignUpViewComponent />
                }
            ]
        },
        {
            path: '/systems',
            element: (
                <AuthGuard>
                    <OmniaLayout>
                        <Suspense fallback={<LoadingScreen inScreen={true} /> }>
                            <Outlet/>
                        </Suspense>
                    </OmniaLayout>
                </AuthGuard>
            ),
            children: [
                {
                    path: '/systems',
                    element: <SystemsViewComponent />
                },
                {
                    path: '/systems/cloud',
                    element: <CloudViewComponent />
                },
                {
                    path: '/systems/detail/:omniaId',
                    element: <OmniaSystemComponent />
                },
                {
                    path: '/systems/server/:serverId',
                    element: <ServerComponent />
                },
            ]
        },
        {
            path: '/teaser/filesearch',
            element: (
                <ServiceGuard serviceIdentifier={['TEASER_FILE_SEARCH']}>
                    <ServiceLayout
                        actionButton={{
                            title: 'Upgrade to GROON',
                            subheader: 'Falls du bereit für den nächsten Schritt bist',
                            buttonLabel: 'Jetzt upgraden',
                            buttonPath: '/teaser/filesearch',
                            icon: 'Airpods'
                        }}
                        sections={[
                            {
                                subheader: null,
                                items: [
                                    {
                                        title: "Dashboard",
                                        path: '/teaser/filesearch',
                                        icon: <OnIcon iconName="BarChartCircle03" size="small"/>,
                                    },
                                    {
                                        title: "Meine Dateien",
                                        path: '/teaser/filesearch/files',
                                        icon: <OnIcon iconName="File04" size="small"/>,
                                    },{
                                        title: "Einstellungen",
                                        path: '/teaser/filesearch/settings',
                                        icon: <OnIcon iconName="Settings01" size="small" />
                                    },

                                ],
                            }
                        ]}
                        menuItems={[
                            {
                                title: "Einstellungen",
                                path: '/teaser/filesearch/settings',
                                icon: <OnIcon iconName="Settings01" size="small"/>,
                            },
                        ]}
                    >
                        <Suspense fallback={<LoadingScreen inScreen={true}/>}>
                            <Outlet/>
                        </Suspense>
                    </ServiceLayout>
                </ServiceGuard>
            ),
            children: [
                {
                    path: '/teaser/filesearch',
                    element: <TeaserFileSearchIndexComponent/>
                },
                {
                    path: '/teaser/filesearch/files',
                    element: <TeaserFileSearchFilesComponent/>
                },
                {
                    path: '/teaser/filesearch/settings',
                    element: <TeaserFileSearchSettingsComponent/>
                },
            ]
        }
    ])
}

export default memo(getRoutes);
