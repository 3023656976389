import {useSettings} from "./use-settings";
import {useAppMode} from "./use-app-mode";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/system";
import {useSelector} from "react-redux";

function useSidebarWidth(){
    const settings = useSettings();
    const theme = useTheme();
    const appMode = useAppMode();

    const collapse = useSelector(state => state.service.collapseNavbar);
    const serviceLayout = useSelector(state => state.service.user?.context?.layout);
    const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

    let sideBarWidth;
    if(appMode.isService){
        sideBarWidth = ((serviceLayout === 'vertical' && collapse) || (serviceLayout !== 'vertical') || lgDown) ? 0 : 250;
    }
    if(appMode.isBackend){
        sideBarWidth = ((appMode.layout === 'vertical' && settings?.navMode === 'hidden') || appMode.layout !== 'vertical' || lgDown) ? 0 : 250;
    }

    return sideBarWidth;
}

export default useSidebarWidth;