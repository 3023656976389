import React from "react";
import PropTypes from 'prop-types';
import {useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import {MobileNav} from '../mobile-nav';
import {TopNav} from './top-nav';
import {useMobileNav} from './use-mobile-nav';
import ErrorHandler from "../../../elements/error-handler";

// const SIDE_NAV_WIDTH = 260;

const PlainLayoutRoot = styled('div')(({theme}) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    // [theme.breakpoints.up('lg')]: {
    //     paddingLeft: SIDE_NAV_WIDTH
    // }
}));

const PlainLayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

export const PlainLayout = (props) => {
    const {children, sections, menuItems, navColor, actionButton, tenantSwitch} = props;
    const mobileNav = useMobileNav();

    return (
        <>
            <TopNav
                onMobileNavOpen={mobileNav.handleOpen}
                menuItems={menuItems}
            />
            <MobileNav
                actionButton={actionButton}
                tenantSwitch={tenantSwitch}
                color={navColor}
                onClose={mobileNav.handleClose}
                open={mobileNav.open}
                sections={sections}
            />
            <PlainLayoutRoot>
                <PlainLayoutContainer>
                    <ErrorHandler>
                        {children}
                    </ErrorHandler>
                </PlainLayoutContainer>
            </PlainLayoutRoot>
        </>
    );
};

PlainLayout.propTypes = {
    children: PropTypes.node,
    navColor: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
    sections: PropTypes.array.isRequired,
    menuItems: PropTypes.array.isRequired
};