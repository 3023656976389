/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { produce } from 'immer';
import {
    UPDATE_PERMISSIONS,
    UPDATE_PERMISSION_GROUPS,
    REMOVE_PERMISSION_GROUPS,
    ADD_PERMISSION_GROUP,
    ADD_REGISTRATIONS,
    UPDATE_REGISTRATION
} from 'src/omnia/store/actions/permissions-actions';
import _ from "lodash";

const initialState = {
    // list of permission group objects
    permissionGroups: [],
    // list of permission options
    permissions: [],
    // list of job registrations
    jobRegistrations: [],
    loadingJobs: true
};

const permissionsReducer = (state = initialState, action) => {

    let tmp = null;

    switch (action.type) {

        case ADD_REGISTRATIONS:
            const { registrations } = action.payload;
            return produce(state, (draft) => {
                draft.loadingJobs = false;
                draft.jobRegistrations = registrations;
            })

        case UPDATE_REGISTRATION:
            const { registration } = action.payload;
            return produce(state, (draft) => {
                let index = _.findIndex(state.jobRegistrations, {id: registration.id});
                if( index !== -1 ){
                    let key = "";
                    for(let i = 0; i < Object.keys(registration).length; i++){
                        key = Object.keys(registration)[i];
                        draft.jobRegistrations[index][key] = registration[key];
                    }
                }
            })

        case UPDATE_PERMISSIONS: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.permissions = action.payload;
            });
        }

        case UPDATE_PERMISSION_GROUPS: {
            return produce(state, (draft) => {
                // Ensure we clear current session
                draft.permissionGroups = action.payload;
            });
        }

        case ADD_PERMISSION_GROUP: {
            if(state.permissionGroups.filter(r => r.id === action.payload.id).length === 0){
                return produce(state, draft => {
                    draft.permissionGroups = state.permissionGroups.concat([action.payload]);
                })
            } else {
                return produce(state, draft => {
                    tmp = state.permissionGroups.indexOf(state.permissionGroups.find(r => r.id === action.payload.id));
                    draft.permissionGroups[tmp] = action.payload;
                })
            }
        }

        case REMOVE_PERMISSION_GROUPS: {
            return produce(state, draft => {
                draft.permissionGroups = state.permissionGroups.filter(r => !action.payload.includes(r.id));
            });
        }

        default: {
            return state;
        }
    }
};

export default permissionsReducer;
