import React from "react";
import {useTranslation} from "react-i18next";
import {List} from "@mui/material";
import {SideNavItem} from "../../layouts/aios/flying-layout/side-nav-item";
import {useLocation} from "react-router-dom";
import usePagination from "../../../hooks/use-pagination";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import OnIcon from "../../elements/icon";
import {useTheme} from "@mui/system";

export default function SitesSidebarSection(){
    // TODO: Later we can have icons after type

    const { t } = useTranslation();
    const theme = useTheme();
    const location = useLocation();
    const { hasPermissions } = useSecurityCheck();
    const elementLoader = usePagination({
        endpoint: 'cms/websites',
        query: {ordering: 'created_at'},
        page: 1,
        pageSize: 500
    })

    const iconNames = {
        normal: 'Globe04',
        maintenance: 'LifeBuoy02',
        landing: 'Download02',
        shop: 'ShoppingCart01',
        blog: 'BookOpen01',
    }

    return (
        <>
            <List sx={{py: 0}}>
                {elementLoader.loading ? (
                    <SideNavItem
                        title={t('common.loading')}
                        icon="Globe04"
                        disabled={true}
                    />
                ) : elementLoader.elements?.map(site => (
                    <SideNavItem
                        title={site.title}
                        icon={iconNames?.[site.kind] || "Globe04"}
                        active={location.pathname?.includes(`/groon/website/site/${site.id}`)}
                        path={`/groon/website/site/${site.id}`}
                        chip={(
                            <>
                                {site.status === 'draft' && (
                                    <OnIcon iconName="Edit05" size="tiny" sx={{color: theme.palette.text.secondary}} />
                                )}
                                {site.status === 'private' && (
                                    <OnIcon iconName="Lock01" size="tiny" sx={{color: theme.palette.text.secondary}} />
                                )}
                                {site.status === 'public' && (
                                    <OnIcon iconName="Globe04" size="tiny" sx={{color: theme.palette.text.secondary}} />
                                )}
                            </>
                        )}
                    />
                ))}
                {hasPermissions("contents_module_manage_websites") && (
                    <SideNavItem
                        title={t('marketing.website.new_site')}
                        icon="PlusCircle"
                        path='/groon/website/site'
                        active={location.pathname === `/groon/website/site`}
                    />
                )}
            </List>
        </>
    )

}