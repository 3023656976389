import React from "react";
import AssistantLogo from "../assistants/assistant-logo";
import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import ClickableInput from "../../../elements/clickable-input";
import OnIcon from "../../../elements/icon";

function AssistantImageTitle({ assistant, info, sx = {}, primaryColor = "textPrimary", ...rest }){

    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            variant="text"
            {...rest}
        >
            <AssistantLogo assistant={assistant}/>
            <Stack direction="column" spacing={0.5}>
                <Typography variant="subtitle1" color={primaryColor} sx={{lineHeight: 1}}>
                    {assistant?.name}
                </Typography>
                {info && (
                    <Typography variant="body2" color="textSecondary" sx={{lineHeight: 1}}>
                        {info}
                    </Typography>
                )}
            </Stack>
        </Stack>
    )
}

export default AssistantImageTitle;