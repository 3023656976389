import React from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    MenuItem,
    Button,
    TextField,
    Typography,
    Grid, Autocomplete, DialogActions, DialogTitle, Stack,
} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {addUser} from "src/omnia/store/actions/users-actions";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import useApi from "src/omnia/hooks/use-api";
import SmartSelector from "../../../../elements/smart-selector";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import { produce } from "immer";
import useCoreFormik from "../../../../../hooks/formik/use-core-formik";
import SaveButton from "../../../../elements/save-button";
import {useIsMobile} from "../../../../../hooks/use-is-mobile";

function UserModal({open, handleClose}) {

    const dispatch = useDispatch();
    const permissions = useSelector(state => state.permissions.permissions);
    const groups = useSelector(state => state.groups.groups);
    const { t } = useTranslation();
    const { isMobile } = useIsMobile();
    const users = useSelector(state => state.users.users);
    const user = useSelector(state => state.account.user);
    const {  notifySuccess } = useNotifications();

    const handleSubmit = (values, {resetForm, setSubmitting}) => {
        setSubmitting(true);
        put('core/users', produce(values, draft => {
            draft.is_admin = values.is_admin === 'true';
        })).then(user => {
            dispatch(addUser(user));
            notifySuccess(t);
            handleClose();
            resetForm();
        }).finally(() => {
            setSubmitting(false);
        })
    };

    const { userFormik } = useCoreFormik(null, handleSubmit);
    const { put } = useApi();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            fullScreen={isMobile}
        >
            <DialogTitle>
                <Typography variant="h4">
                    {t('dialogs.add_user.title')}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {t('dialogs.add_user.description')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={userFormik.handleSubmit}>
                    <Box mt={2}>
                        <Typography variant="h5" style={{fontSize: 16}}>
                            {t("common.general")}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Grid container spacing={1}>
                            <Grid item sm={4} xs={12}>
                                <TextField
                                    error={Boolean(userFormik.touched.title && userFormik.errors.title)}
                                    fullWidth
                                    helperText={userFormik.touched.title && userFormik.errors.title}
                                    label={t('attributes.title')}
                                    name="title"
                                    onBlur={userFormik.handleBlur}
                                    onChange={userFormik.handleChange}
                                    value={userFormik.values.title}
                                />
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <TextField
                                    error={Boolean(userFormik.touched.first_name && userFormik.errors.first_name)}
                                    fullWidth
                                    helperText={userFormik.touched.first_name && userFormik.errors.first_name}
                                    label={t('attributes.first_name')}
                                    name="first_name"
                                    onBlur={userFormik.handleBlur}
                                    onChange={userFormik.handleChange}
                                    value={userFormik.values.first_name}

                                />
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <TextField
                                    error={Boolean(userFormik.touched.last_name && userFormik.errors.last_name)}
                                    fullWidth
                                    helperText={userFormik.touched.last_name && userFormik.errors.last_name}
                                    label={t('attributes.last_name')}
                                    name="last_name"
                                    onBlur={userFormik.handleBlur}
                                    onChange={userFormik.handleChange}
                                    value={userFormik.values.last_name}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2}>
                        <Grid container spacing={1}>
                            <Grid sm={4} xs={12} item>
                                <TextField
                                    error={Boolean(userFormik.touched.email && userFormik.errors.email)}
                                    fullWidth
                                    helperText={userFormik.touched.email && userFormik.errors.email}
                                    label={t('attributes.email')}
                                    name="email"
                                    onBlur={userFormik.handleBlur}
                                    onChange={userFormik.handleChange}
                                    value={userFormik.values.email}

                                />
                            </Grid>
                            <Grid sm={4} xs={12} item>
                                <TextField
                                    error={Boolean(userFormik.touched.username && userFormik.errors.username)}
                                    fullWidth
                                    helperText={userFormik.touched.username && userFormik.errors.username}
                                    label={t('attributes.username')}
                                    name="username"
                                    onBlur={userFormik.handleBlur}
                                    onChange={userFormik.handleChange}
                                    value={userFormik.values.username}

                                />
                            </Grid>
                            <Grid sm={4} xs={12} item>
                                <TextField
                                    error={Boolean(userFormik.touched.position && userFormik.errors.position)}
                                    fullWidth
                                    helperText={userFormik.touched.position && userFormik.errors.position}
                                    label={t('attributes.position')}
                                    name="position"
                                    onBlur={userFormik.handleBlur}
                                    onChange={userFormik.handleChange}
                                    value={userFormik.values.position}

                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2}>
                        <Grid container spacing={1}>
                            <Grid sm={6} xs={6} item>
                                <Autocomplete
                                    options={users}
                                    getOptionLabel={(option) => option.name}
                                    value={users.find((user) => user.id === userFormik.values.supervisor)}
                                    onChange={(event, newValue) => {
                                        userFormik.setFieldValue('supervisor', newValue ? newValue.id : '');
                                    }}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(userFormik.touched.supervisor && userFormik.errors.supervisor)}
                                            helperText={userFormik.touched.supervisor && userFormik.errors.supervisor}
                                            label={t("core.supervisor")}
                                            onBlur={userFormik.handleBlur}
                                            name="supervisor"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid sm={6} xs={6} item>
                                <Autocomplete
                                    multiple
                                    options={groups}
                                    getOptionLabel={(option) => option.name}
                                    value={groups.filter((group) => userFormik.values.user_groups?.includes(group.id))}
                                    onChange={(event, newValue) => {
                                        userFormik.setFieldValue('user_groups', newValue?.map(g => g.id));
                                    }}
                                    error={Boolean(userFormik.touched.user_groups && userFormik.errors.user_groups)}
                                    helperText={userFormik.touched.user_groups && userFormik.errors.user_groups}
                                    onBlur={userFormik.handleBlur}
                                    name="user_groups"
                                    getOptionSelected={(option, values) => values?.includes(option.id)}
                                    renderInput={(params) => <TextField label={t("layout.groups")} {...params} />}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={6}>
                        <Typography variant="h5" style={{fontSize: 16}}>
                            {t("common.access")}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Grid container spacing={1}>
                            <Grid sm={user.is_admin ? 6 : 12} xs={12} item>
                                {permissions.length > 0 ? (
                                    <SmartSelector
                                        label={t("mgt.index.permissions")}
                                        options={permissions}
                                        name="permission_groups"
                                        handleChange={(event) => {
                                            userFormik.setFieldValue('permission_groups', event.target.value);
                                        }}
                                        values={userFormik.values.permission_groups}
                                    />
                                ) : (
                                    <TextField
                                        fullWidth
                                        label={t('mgt.index.no_permissions_found')}
                                        disabled
                                        onBlur={userFormik.handleBlur}
                                        onChange={userFormik.handleChange}

                                    />
                                )}
                            </Grid>
                            {user.is_admin && (
                                <Grid sm={6} xs={12} item>
                                    <TextField
                                        select
                                        fullWidth
                                        onChange={data => {
                                            userFormik.setFieldValue('is_admin', data.target.value);
                                        }}
                                        onBlur={userFormik.handleBlur}
                                        value={userFormik.values.is_admin}
                                        label={t('mgt.index.is_admin')}
                                        inputProps={{
                                            name: 'is_admin',
                                            id: 'group-select',
                                        }}
                                    >
                                        <MenuItem value={'true'}>
                                            {t("common.yes")}
                                        </MenuItem>
                                        <MenuItem value={'false'}>
                                            {t("common.no")}
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                    <Button
                        disabled={userFormik.isSubmitting}
                        onClick={handleClose}
                        type="button"
                        variant="outlined"
                    >
                        {t("common.cancel")}
                    </Button>
                    <SaveButton
                        formik={userFormik}

                        // loading={userFormik.isSubmitting}
                        // onClick={userFormik.handleSubmit}
                        // noIcon
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

UserModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
}

export default UserModal;