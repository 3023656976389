import React from 'react';
import {Tooltip} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {TeamPopover} from './team-popover';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import OnIcon from "../../../elements/icon";
import ClickableInput from "../../../elements/clickable-input";
import {useTheme} from "@mui/system";

export const TeamButton = () => {
    const popover = usePopover();
    const theme = useTheme();
    const { t } = useTranslation();
    const context = useSelector(state => state.service.user.context);

    if(!context?.is_company_context)
        return null;

    return (
        <>
            <Tooltip enterDelay={1000} title={t("layout.team")}>
                <ClickableInput sx={{textAlign: 'center', color: 'textSecondary'}} ref={popover.anchorRef} onClick={popover.handleOpen}>
                    <OnIcon iconName="Users01" sx={{color: theme.palette.text.secondary, mx: 0.5}} />
                </ClickableInput>
            </Tooltip>
            <TeamPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                open={popover.open}
            />
        </>
    );
};
