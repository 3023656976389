import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronRightIcon from '@untitled-ui/icons-react/build/esm/ChevronRight';
import {Box, ButtonBase, Collapse, IconButton, SvgIcon, Tooltip, Typography} from '@mui/material';
import {RouterLink} from 'src/omnia/components/elements/router-link';
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useNavigate} from "react-router-dom";

export const SideNavItem = (props) => {
    const {
        children,
        onClick = null,
        active = false,
        depth = 0,
        item = null,
    } = props;

    const [open, setOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const navigate = useNavigate();
    const {isMobile} = useIsMobile();

    const {t} = useTranslation();

    const handleToggle = useCallback((event) => {

        if (event.target.closest('.MuiIconButton-root')) {
            return; // Ignore clicks originating from the IconButton
        }

        if(isMobile){
            setOpen((prevOpen) => !prevOpen);
        } else if(item?.items?.filter?.(item => item.path).length > 0) {
            navigate(item?.items?.filter?.(item => item.path)[0].path);
        } else {
            setOpen((prevOpen) => !prevOpen);
        }
    }, []);

    const offset = depth === 0 ? 0 : (depth - 1) * 16;

    const buttonBaseStyle = {
        alignItems: 'center',
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        pl: `${(depth === 0 ? 5 : 42) + offset}px`,
        pr: '14px',
        // py: (depth === 0) ? '5px' : '8px',
        py: 0,
        textAlign: 'left',
        width: '100%',
        ...(active && {
            ...(depth === 0 && {
                backgroundColor: 'var(--nav-item-active-bg)'
            })
        }),
        '&:hover': {
            backgroundColor: 'var(--nav-item-hover-bg)'
        }
    }

    const titleStyle = {
        color: 'var(--nav-item-color)',
        flexGrow: 1,
        fontFamily: (theme) => theme.typography.fontFamily,
        fontWeight: depth > 0 ? 400 : 600,
        py: depth > 0 ? 1.5 : 0,
        maxWidth: 'calc(100% - 40px)',
        whiteSpace: 'nowrap',
        ...(active && {
            color: 'var(--nav-item-active-color)'
        }),
        ...(item?.disabled && {
            color: 'var(--nav-item-disabled-color)'
        })
    }

    // Initiate content
    let content;

    // Branch
    if (children) {
        content = (
            <li id='side-nav-item-with-childs'>
                <ButtonBase
                    disabled={item?.disabled}
                    onClick={handleToggle}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    sx={buttonBaseStyle}
                >
                    {(depth === 0) && (
                        <Box
                            component="span"
                            sx={{
                                alignItems: 'center',
                                color: 'var(--nav-item-icon-color)',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                py: (depth === 0) ? '5px' : '8px',
                                mr: 2,
                                ...(active && {
                                    color: 'var(--nav-item-icon-active-color)'
                                })
                            }}
                        >
                            <IconButton
                                sx={{
                                    ...{
                                        borderRadius: '5px',
                                        padding: 0.25,
                                    },
                                    ...(active ? {
                                        color: 'var(--nav-item-icon-active-color)'
                                    } : {
                                        color: 'var(--nav-item-icon-color)',
                                    }),
                                    ...((hovered || open) ? {
                                        backgroundColor: 'var(--nav-item-hover-bg)',
                                    } : {}),
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setOpen((prevOpen) => !prevOpen);
                                }}
                                onMouseDown={(event) => {
                                    event.stopPropagation(); // Stops mousedown event
                                }}
                            >
                                {(hovered || open) ? (
                                    <SvgIcon
                                        fontSize="small"
                                        sx={{
                                            ...(active ? {
                                                color: 'var(--nav-item-icon-active-color)'
                                            } : {
                                                color: 'var(--nav-item-chevron-color)',
                                            })
                                        }}
                                    >
                                        {open ? <ChevronDownIcon/> : <ChevronRightIcon/>}
                                    </SvgIcon>
                                ) : (
                                    <OnIcon iconName={item?.icon} size="small" />
                                )}
                            </IconButton>
                        </Box>
                    )}
                    <Box
                        component="span"
                        sx={titleStyle}
                    >
                        <Typography
                            variant={depth > 0 ? "body2" : "body1"}
                            sx={{fontWeight: depth > 0 ? 400 : 600, lineHeight: 1, maxWidth: '100%'}}
                            noWrap
                        >
                            {t(item?.title)}
                        </Typography>
                    </Box>

                </ButtonBase>
                <Collapse in={open}>
                    {children}
                </Collapse>
            </li>
        );
    }

    // Leaf
    else {
        content = (
            <li id='side-nav-item-plain'>
                <ButtonBase
                    disabled={item?.disabled}
                    sx={buttonBaseStyle}
                    component={RouterLink}
                    href={item?.path}
                >
                    {(depth === 0) && (
                        <Box
                            component="span"
                            sx={{
                                alignItems: 'center',
                                color: 'var(--nav-item-icon-color)',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                py: (depth === 0) ? '5px' : '8px',
                                mr: 2,
                                ...(active && {
                                    color: 'var(--nav-item-icon-active-color)'
                                })
                            }}
                        >
                            <IconButton
                                sx={{
                                    borderRadius: '5px',
                                    padding: 0.25,
                                    ...(active ? {
                                        color: 'var(--nav-item-icon-active-color)'
                                    } : {
                                        color: 'var(--nav-item-icon-color)',
                                    }),
                                }}
                            >
                                <OnIcon iconName={item?.icon} size="small" />
                            </IconButton>
                        </Box>
                    )}
                    <Box
                        component="span"
                        sx={titleStyle}
                    >
                        <Typography
                            variant={depth > 0 ? "body2" : "body1"}
                            sx={{fontWeight: depth > 0 ? 400 : 600, lineHeight: 1, maxWidth: 'calc(100% - 10px)'}}
                            noWrap
                        >
                            {t(item?.title)}
                        </Typography>
                    </Box>
                    {item?.label && (
                        <Box
                            component="span"
                            sx={{ml: 2}}
                        >
                            {item?.label}
                        </Box>
                    )}
                    {item?.chip || null}
                </ButtonBase>
            </li>
        )
    }

    if(item?.component){
        return item?.component;
    } else {
        if(item?.tooltip){
            return (
                <Tooltip enterDelay={1000} title={t(item?.tooltip)} placement="right">
                    {content}
                </Tooltip>
            );
        }
        return content;
    }

};

SideNavItem.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    depth: PropTypes.number,
    onClick: PropTypes.func,
    item: PropTypes.object.isRequired,
};
