import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import {Fade, IconButton, Link, List, Stack, Typography} from "@mui/material";
import OnIcon from "../../../elements/icon";
import usePopover from "../../../../hooks/use-popover";
import SmartPopper from "../../../elements/smart-popper";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import ShareButton from "../../../elements/sharing/share-button";
import RemoveSharableObject from "../../../elements/sharing/remove-sharable-object";

function AssistantTitle(props) {

    const {
        assistant,
        variant = 'subtitle1',
        nameClickActive = false,
        justifyContent = 'flex-start',
        onDelete,
        ...rest
    } = props;

    const popover = usePopover();
    const navigate = useNavigate();
    const [ hovered, setHovered ] = useState(false);

    const handleOpenMore = (event) => {
        event.preventDefault();
        event.stopPropagation();
        popover.handleOpen();
    }

    const handleEdit = useCallback(() => {
        navigate('/groon/home/assistants/' + assistant.id);
    }, [assistant]);

    return (
        <Stack
            direction="row"
            alignItems="center"
            onMouseLeave={() => setHovered(false)}
            onMouseEnter={() => setHovered(true)}
            sx={{width: '100%'}}
            justifyContent={justifyContent}
            spacing={2}
            {...rest}
        >
            {nameClickActive ? (
                <Link
                    underline="hover"
                    variant={variant}
                    component={RouterLink}
                    color="textPrimary"
                    to={"/groon/home/assistants/" + assistant?.id}
                >
                    {assistant?.name}
                </Link>
            ) : (
                <Typography variant={variant} color="text.primary">
                    {assistant?.name}
                </Typography>
            )}
            <Fade in={hovered}>
                <Stack direction="row" spacing={1} alignItems="center">
                    {(!nameClickActive && assistant?.can_edit) && (
                        <IconButton size="small" onClick={handleEdit}>
                            <OnIcon iconName="Edit01" size="small" />
                        </IconButton>
                    )}
                    {assistant?.can_edit && (
                        <IconButton
                            ref={popover.anchorRef}
                            size="small"
                            onClick={handleOpenMore}
                        >
                            <OnIcon iconName="DotsHorizontal" size="small" />
                        </IconButton>
                    )}
                    <SmartPopper
                        width='auto'
                        popover={popover}
                        placement="right-start"
                        disableHover={true}
                    >
                        <List>
                            <ShareButton
                                entity={assistant}
                                endpoint='core/assistants'
                                tooltipPlacement="right"
                            />
                            <RemoveSharableObject
                                entity={assistant}
                                endpoint='core/assistants'
                                tooltipPlacement="right"
                                onRemove={onDelete}
                            />
                        </List>
                    </SmartPopper>
                </Stack>
            </Fade>
        </Stack>
    )
}

AssistantTitle.propTypes = {
    assistant: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    justifyContent: PropTypes.string,
    variant: PropTypes.string,
    nameClickActive: PropTypes.bool,

}

export default AssistantTitle;