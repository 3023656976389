import {useTranslation} from "react-i18next";
import {
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    ListItemText,
    MenuItem,
    Stack,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import useCrmFormik from "../../../hooks/formik/use-crm-formik";
import SmartSelector from "../../elements/smart-selector";
import {Box, useTheme} from "@mui/system";
import React, {useEffect, useState} from "react";
import AdditionalFieldsComponent from "../../elements/form-additional-fields";
import {NumericFormat} from "react-number-format";
import {APP_SETTING} from "../../../../setup";
import {QuillEditor} from "../../elements/quill-editor";
import SelectionTiles from "../../elements/selection-tiles";
import ListIcon from "@untitled-ui/icons-react/build/esm/Dotpoints02";
import FlowIcon from "@untitled-ui/icons-react/build/esm/Dataflow03";
import ServiceIcon from "@untitled-ui/icons-react/build/esm/Divider";
import {TextEditor} from "../../elements/text-editor/text-editor";
import InlineEditInput from "../../elements/inline-edit-input";
import ShareWithGroupStack from "../../elements/sharing/share-with-group-stack";

function ContextForm({formik, minimal, ...rest}) {

    const {t} = useTranslation();
    const {
        CONTEXT_REQUIRED_FIELDS,
        CONTEXT_ACCESS,
        LEAD_PIPELINE_STATES,
        CONTACT_TYPES,
        CONTEXT_KINDS
    } = useCrmFormik();

    useEffect(() => {
        formik.setFieldValue('title', formik.values?.name)
    }, [formik.values?.name]);

    return (
        <>
            {(formik.values.kind === null) ? (
                <SelectionTiles
                    options={CONTEXT_KINDS}
                    onSelect={(option) => {
                        formik.setFieldValue('kind', option.value);

                        if (option.value === 'list') {
                            formik.setFieldValue('required_fields', ['first_name', 'last_name', 'email']);
                            formik.setFieldValue('destination_contact_type', 'contact');
                        }
                        if (option.value === 'service') {
                            if ((APP_SETTING?.services?.length || 0) === 1) {
                                formik.setFieldValue('service_identifier', APP_SETTING?.services[0].identifier);
                                formik.setFieldValue('name', APP_SETTING?.services[0].value);
                            }
                            formik.setFieldValue('required_fields', ['first_name', 'last_name', 'email', 'password']);
                            formik.setFieldValue('destination_contact_type', 'customer');
                            formik.setFieldValue('destination_lead_pipeline_state', 'converted');
                        }
                        if (option.value === 'form') {
                            formik.setFieldValue('required_fields', ['first_name', 'last_name', 'email']);
                            formik.setFieldValue('destination_contact_type', 'contact');
                        }
                    }}
                />
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{width: '100%', mb: 4}}
                            spacing={2}
                        >

                            <Stack sx={{width: '100%'}}>
                                <InlineEditInput
                                    variant="h4"
                                    placeholder={t('attributes.name')}
                                    fullWidth
                                    size='small'
                                    name="name"
                                    {...formik.getFieldProps('name')}
                                    onChange={(value) => formik.setFieldValue('name', value)}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                />
                                {(formik.touched.name && formik.errors.name) && (
                                    <Typography variant="caption"
                                                color={(formik.touched.name && Boolean(formik.errors.name)) ? 'error' : 'text.secondary'}>
                                        {formik.touched.name && formik.errors.name}
                                    </Typography>
                                )}
                            </Stack>

                            <TextField
                                sx={{width: 150}}
                                select
                                label={t('attributes.access_mode')}
                                {...formik.getFieldProps('access_mode')}
                                error={formik.touched.access_mode && Boolean(formik.errors.access_mode)}
                                helperText={formik.touched.access_mode && formik.errors.access_mode}
                            >
                                {CONTEXT_ACCESS
                                    .map((kind, index) => (
                                        <MenuItem value={kind} key={kind}>
                                            {t('connections.context.access_mode_' + kind)}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Stack>
                    </Grid>

                    {formik.values.kind === 'service' && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                select
                                autoFocus={true}
                                value={formik.values.service_identifier}
                                label={t('connections.context.service_identifier')}
                                onChange={event => {
                                    const service = APP_SETTING?.services?.find(s => s.identifier === event.target.value);
                                    formik.setFieldValue('name', service?.name);
                                    formik.setFieldValue('service_identifier', event.target.value);
                                }}
                                error={formik.touched.service_identifier && Boolean(formik.errors.service_identifier)}
                                helperText={formik.touched.service_identifier && formik.errors.service_identifier}
                            >
                                {APP_SETTING?.services?.map((service, index) => (
                                    <MenuItem key={index} value={service.identifier}>{t(service.name)}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}

                    <Grid item xs={12} sm={formik.values.destination_contact_type === 'customer' ? 6 : 12}>
                        <TextField
                            fullWidth
                            select
                            label={t('connections.context.contact_type')}
                            {...formik.getFieldProps('destination_contact_type')}
                            error={formik.touched.destination_contact_type && Boolean(formik.errors.destination_contact_type)}
                            helperText={formik.touched.destination_contact_type && formik.errors.destination_contact_type || t('connections.context.contact_type_help_text')}
                        >
                            {CONTACT_TYPES.map((kind, index) => (
                                <MenuItem key={'crm-type-' + index} value={kind}>{t(kind)}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {formik.values.destination_contact_type === 'customer' && (
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                label={t('connections.context.lead_state')}
                                {...formik.getFieldProps('destination_lead_pipeline_state')}
                                error={formik.touched.destination_lead_pipeline_state && Boolean(formik.errors.destination_lead_pipeline_state)}
                                helperText={formik.touched.destination_lead_pipeline_state && formik.errors.destination_lead_pipeline_state || t('connections.context.lead_state_help_text')}
                            >
                                {LEAD_PIPELINE_STATES.map((state, index) => (
                                    <MenuItem key={'lead-state-' + index} value={state}>{t(state)}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <SmartSelector
                            values={formik.values.required_fields}
                            options={CONTEXT_REQUIRED_FIELDS.map(kind => ({id: kind, name: t(kind)}))}
                            label={t('attributes.required_fields')}
                            multiple={true}
                            handleChange={formik.handleChange}
                            error={formik.touched.required_fields && Boolean(formik.errors.required_fields)}
                            helperText={formik.touched.required_fields && formik.errors.required_fields}
                            name='required_fields'
                        />
                    </Grid>

                    {formik.values.double_opt_in && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="overline" sx={{lineHeight: 1}}>
                                    {t("connections.context.double_opt_in_email")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('attributes.email_subject')}
                                    {...formik.getFieldProps('double_opt_in_email_subject')}
                                    error={formik.touched.double_opt_in_email_subject && Boolean(formik.errors.double_opt_in_email_subject)}
                                    helperText={formik.touched.double_opt_in_email_subject && formik.errors.double_opt_in_email_subject}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextEditor
                                    sx={{minHeight: 200, maxHeight: 600, overflow: 'auto'}}
                                    onUpdate={({editor}) => {
                                        formik.setFieldValue('double_opt_in_email_body', editor.getHTML());
                                    }}
                                    content={formik.values.double_opt_in_email_body}
                                />
                            </Grid>
                        </>
                    )}

                    {formik.values.kind === 'service' && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        onChange={(event, val) => {
                                            formik.setFieldValue('is_company_context', val);
                                        }}
                                        checked={formik.values.is_company_context}
                                        name='is_company_context'
                                        value={formik.values.is_company_context}
                                    />
                                )}
                                label={<Box>
                                    <ListItemText
                                        primary={t("connections.context.service_for_companies")}
                                        primaryTypographyProps={{
                                            noWrap: true,
                                            variant: 'body1',
                                            color: 'textPrimary'
                                        }}
                                    />
                                </Box>}
                                labelPlacement="end"
                            />
                        </Grid>
                    )}

                    {(!minimal && formik.values.kind === 'service') && (
                        <>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            onChange={(event, val) => {
                                                formik.setFieldValue('use_multi_factor_authentication', val);
                                            }}
                                            disabled={true}
                                            checked={formik.values.use_multi_factor_authentication}
                                            name='use_multi_factor_authentication'
                                            value={formik.values.use_multi_factor_authentication}
                                        />
                                    )}
                                    label={<Box>
                                        <ListItemText
                                            primary={t("connections.context.service_mfa")}
                                            primaryTypographyProps={{
                                                noWrap: true,
                                                variant: 'body1',
                                                color: 'textDisabled'
                                            }}
                                        />
                                    </Box>}
                                    labelPlacement="end"
                                />
                            </Grid>
                        </>
                    )}

                    {!formik.values.id && (
                        <Grid item xs={12}>
                            <ShareWithGroupStack formik={formik} variant="checkbox"/>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    onChange={(event, val) => {
                                        formik.setFieldValue('double_opt_in', val);
                                    }}
                                    checked={formik.values.double_opt_in}
                                    name='double_opt_in'
                                    value={formik.values.double_opt_in}
                                />
                            )}
                            label={<Box>
                                <ListItemText
                                    primary={t("connections.context.double_opt_in")}
                                    primaryTypographyProps={{
                                        noWrap: true,
                                        variant: 'body1',
                                        color: 'textPrimary'
                                    }}
                                />
                            </Box>}
                            labelPlacement="end"
                        />
                    </Grid>

                    <Grid item xs={12} mt={2}>
                        <Divider/>
                        <AdditionalFieldsComponent
                            sx={{mt: 2}}
                            formik={formik}
                            name='additional_fields'
                            addLabel={t('common.add_additional_fields')}
                        />
                    </Grid>
                </Grid>
            )}

        </>
    )

}

export default ContextForm;