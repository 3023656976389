export const UPDATE_PERMISSIONS = '@permissions/update-permissions';
export const REMOVE_PERMISSION_GROUPS = '@permissions/remove-permission-groups';
export const ADD_PERMISSION_GROUP = '@permissions/add-permission-group';
export const UPDATE_PERMISSION_GROUPS = '@permissions/update-permission-groups';
export const ADD_REGISTRATIONS = '@permissions/add-registration';
export const UPDATE_REGISTRATION = '@permissions/update-registration';

export function addRegistrations(registrations){
    return (dispatch) => dispatch({
        type: ADD_REGISTRATIONS,
        payload: {
            registrations
        }
    })
}

export function updateRegistration(registration){
    return (dispatch) => dispatch({
        type: UPDATE_REGISTRATION,
        payload: {
            registration
        }
    })
}

export function storePermissions(permissionOptions){
    return (dispatch) => dispatch({
        type: UPDATE_PERMISSIONS,
        payload: permissionOptions
    });
}

export function storePermissionGroups(permissionGroups) {
    return (dispatch) => dispatch({
        type: UPDATE_PERMISSION_GROUPS,
        payload: permissionGroups
    });
}

export function addPermissionGroup(newPermissionGroup){
    return (dispatch) => dispatch({
        type: ADD_PERMISSION_GROUP,
        payload: newPermissionGroup
    })

}

export function deletePermissionGroups(removedIds) {
    return (dispatch) => dispatch({
        type: REMOVE_PERMISSION_GROUPS,
        payload: removedIds
    })

}
